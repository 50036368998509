/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppThunkAction } from '../index';
import { Action, Reducer, AnyAction } from 'redux';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ConfigurationState {
    appInsightsConfig: string;
}

// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something
// that is going to happen.

export interface RequesConfigValueAction extends AnyAction {
    type: 'REQUEST_CONFIG_VALUE';
}

export interface ReceiveConfigValueStringAction extends AnyAction {
    type: 'RECEIVE_CONFIG_VALUE';
    payload: any;
}

export interface FailedConfigValueStringAction extends AnyAction {
    type: 'FAILED_CONFIG_VALUE';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type'
// properties contain one of the
// declared type strings (and not any other arbitrary string).
export type AreaAction = RequesConfigValueAction | ReceiveConfigValueStringAction | FailedConfigValueStringAction;
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger
// a state transition.
// They don't directly mutate state, but they can have external side-effects
// (such as loading data).

export const actionCreators = {
  requestAppInsightsConfig: ():
  AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.authentication && appState.authentication.isLoading === false) {
      dispatch({ 
        type: 'REQUEST_CONFIG_VALUE',
        http: {
          verb: 'GET',
          endpoint: '/api/configuration/appinsights',
          successAction: 'RECEIVE_CONFIG_VALUE',
          failureAction: 'FAILED_CONFIG_VALUE',
        }, 
      });
    }
  },
};

// REDUCER - For a given state and action, returns the new state. To support time travel,
// this must not mutate the old state.

const unloadedState: ConfigurationState = {
  appInsightsConfig: '',
};

export const reducer: Reducer<ConfigurationState> = (state: ConfigurationState | undefined,
  incomingAction: Action):ConfigurationState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as AreaAction;
  switch (action.type) {
  case 'REQUEST_CONFIG_VALUE':
    return {
      ...unloadedState,
    };
  case 'RECEIVE_CONFIG_VALUE':
    return {
      appInsightsConfig: action.payload.configValue,
    };
  case 'FAILED_CONFIG_VALUE':
    return {
      ...unloadedState,
    };
  default:
    return state;
  }
};

