import * as React from 'react';
import { useState } from 'react';
import { SeasonalContent as SeasonalContentModel } from '../../../store/DailyForecast/DailyForecast';
import { Loading } from '../../Shared/Loading/Loading';

type IProps = {
  content: SeasonalContentModel,
    onImageClick: (image: string[]) => void;

};

const SeasonalRecipeContent: React.FC<IProps> = ({ content, onImageClick }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageClick = () => {
    onImageClick(content.contentImages);
  };

  return (
    <>
      {content.featuredImage && !imageLoaded && <Loading />}

      {content.featuredImage &&
      <div key={content.featuredImage}
        className="item-wrapper">
        <img onLoad={() => setImageLoaded(true)}
          src={content.featuredImage} 
          onClick={handleImageClick}
        />
        <div className="recipe-title-wrapper">
          <h2 className="recipe-title">{content.title}</h2>
        </div>
        <div className="recipe-button-wrapper">
          <button className="recipe-button" 
            onClick={handleImageClick}
          >{content.description}</button>
        </div>
      </div>
      }
    </>
  );
};

export default SeasonalRecipeContent;
