function NotFoundPage () {
  return(
    <div className="app">
      <div className="body-wrapper">
        <div className='title-wrapper'>
          <h1 className="title">{'Uh oh, seems you got a bit lost. Kindly select one of the links in the menu above ❤️'}</h1>
        </div>
      </div>
    </div>
  );
}

export default (NotFoundPage);
