import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import NotificationItem from './NotificationItem';
import { ApplicationState } from '../../store';
import { ConnectedProps, connect } from 'react-redux';
import { actionCreators, Notification } from '../../store/Notification/Notification';
import { Loading } from '../Shared/Loading/Loading';

const mapState = (state: ApplicationState) => ({
  notificationState: state.notification,
});

const mapDispatch = {
  ...actionCreators,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface NotificationPanelProps {
  handleClosePanel: () => void;
}

type CombinedProps = PropsFromRedux & NotificationPanelProps;

const NotificationPanel: React.FC<CombinedProps> = ({
  handleClosePanel,
  requestNotifications,
  markNotificationAsRead,
  markAllNotificationsAsRead,
  notificationState,
}) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    requestNotifications();
  }, [requestNotifications]);

  useEffect(() => {
    if(notificationState && notificationState.notifications.length)
      setNotifications([...notificationState.notifications]);
    
  }, [notificationState]);

  const handleMarkNotificationAsRead = (notificationId: number) => {
    markNotificationAsRead(notificationId);
  };

  const handleMarkAllAsRead = () => {
    markAllNotificationsAsRead();
  };

  return (
    <div className="notification-panel">
      <div className="notification-header">
        <div className='notification-header-wrapper'>
          <span className="notification-header-title">Notifications</span>
          <FontAwesomeIcon
            icon={faTimes}
            className="notification-close"
            onClick={handleClosePanel}
          />
        </div>
        <span className="mark-all-as-read"
          onClick={handleMarkAllAsRead}>

          <FontAwesomeIcon icon={faCheckDouble}
            className="mark-icon"
            size='sm' />
          <span>Mark all as read</span>
        </span>
      </div>
      <div className="notification-content">
        {notificationState?.isLoading && <Loading/>}
        {!notificationState?.isLoading && notifications.length === 0 ? (
          <p>No notifications</p>
        ) : (
          <ul className="notification-list">
            {notifications.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                markNotificationAsRead={handleMarkNotificationAsRead}
                handleClosePanel={handleClosePanel}
              />
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default connector(NotificationPanel);
