import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';

function IdleHandler(){
  const events= ['click','scroll','load','keydown', 'ontouchstart'];
  const oneMinute = 60000;
  const fiveMinutes = oneMinute * 5;
  const oneHour = oneMinute * 60;
  const lastInteractionAt = useRef(new Date());
  const [showModal, setShowModal] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(()=>{
    addEventListeners();
    setIdleCheckTimer();
  }, []);

  function eventHandler(){
    const now = new Date();
    lastInteractionAt.current = now;
  }

  const addEventListeners=()=>{
    events.forEach(eventName => {
      window.addEventListener(eventName, eventHandler);
    });
  };

  function setIdleCheckTimer(){
    setTimeout(() => {
      if(isIdle()){
        setShowModal(true);
        setNavigationTimer();
      }
      setIdleCheckTimer();
    }, oneMinute);
  }

  function setNavigationTimer(){
    setTimeout(() => {
      if(isIdle()){
        navigate('/');
        location.reload();
      }
    }, fiveMinutes);
  }

  function isIdle(){
    const now = new Date().getTime();
    const lastInteraction = lastInteractionAt.current.getTime();
    return lastInteraction + oneHour < now;
  }

    
  return (
    <>
      { showModal &&
        <ConfirmationModal 
          title='Are you still there?'
          onContinue={() => setShowModal(false)}
          continueText="Yes"
        />
      }
    </>
  );
        
}
    
export default IdleHandler;