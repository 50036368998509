import { useEffect, useState } from 'react';

interface IProps {
  pageSize: number;
  totalRecords: number;
  changePage: Function;
  currentPage: number;
}

const Pagination = (props: IProps) => {
  const { pageSize, totalRecords, changePage, currentPage } = props;
  
  const constructPages = () => {
    const totalPages = Math.ceil(totalRecords / pageSize);
    const pages: JSX.Element[] = [];

    // Add prev button
    pages.push(
      <div className={`pagination-page ${currentPage <= 1 ? 'disabled': ''}`}
        key='prev'
        onClick={() => currentPage > 1 && changePage(currentPage - 1)}>
          Prev
      </div>,
    );

    // Add page buttons
    for (let i = 1; i <= totalPages; i++) {

      // Show only the current page, the pages around it, and the first and last pages
      const pageCanBeDisplayed = i === 1 || i === currentPage || i === totalPages || (i >= currentPage - 2 && i <= currentPage + 2);

      // Display 3 dots after the third page and before the last page
      const displayEllipsis = i === currentPage + 3 || i === currentPage - 3;

      
      if (pageCanBeDisplayed) {
        pages.push(
          <div className={`pagination-page ${i === currentPage ? 'active' : ''}`}
            key={i}
            onClick={() => changePage(i)}>
            {i}
          </div>,
        );
      } else if(displayEllipsis) {
        pages.push(
          <div className='dummy-page'
            key={i}>
            ...
          </div>,
        );
      }
    }

    // Add next button
    pages.push(
      <div className={`pagination-page ${currentPage >= totalPages ? 'disabled': ''}`}
        key='next'
        onClick={() => currentPage < totalPages && changePage(currentPage + 1)}>
          Next
      </div>,
    );

    return pages;
  };

  return (
    <div className="pagination-wrapper">
      <div className="pagination-container">
        {
          constructPages()
        }
      </div>
    </div>
  );
};

export default Pagination;
