/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { ApplicationState } from '../../../store';
import * as SuggestionStore from '../../../store/Suggestion/Suggestion';
import * as AuthenticationStore from '../../../store/Authentication/Authentication';
import { connect, ConnectedProps } from 'react-redux';
import { WithRouter } from '../../Shared/Routing/WithRouter';
import { Loading } from '../../Shared/Loading/Loading';
import dateFormat from 'dateformat';
import HomePage from '../../Home/HomePage/HomePage';
import { Animated } from 'react-animated-css';
import SuggestionForm from '../Suggestion/SuggestionForm';
import ToolTips from '../../Shared/ToolTips/Tooltips';
import ConfirmationModal from '../../Shared/ConfirmationModal/ConfirmationModal';
import { useState, useEffect } from 'react';
import { SuggestionEditAction } from '../../../enums/SuggestionEditAction';
import { CategoryFilters } from '../../../enums/CategoryFilters';
import { IAdminPageState, IEditSuggestionModel, ITimeSlot } from '../../../interfaces/Admin';
import PermissionHelper from '../../../helpers/PermissionHelper';
import { Permissions } from '../../../store/Authentication/Authentication';
import DateUtils from '../../../helpers/DateUtils';

const mapState = (state: ApplicationState) => ({
  suggestionState: state.suggestion,
  authentication: state.authentication,
});

const mapDispatch = {
  ...SuggestionStore.actionCreators,
  ...AuthenticationStore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

type IProps = PropsFromRedux;

const MORNING_BREW_TITLE = 'View your Morning Brew';

function Admin(props: IProps) {
  const [state, setState] = useState<IAdminPageState>({
    selectedScoringEntity: {
      dataId: '',
      name: '',
      type: '',
      bandingId: 0,
      openHour: '',
      closeHour: '',
      openHourSun: '',
      closeHourSun: '',
      latitude: 0,
      longitude: 0,
    },
    displayScoringEntities: true,
    displaySuggestions: false,
    displayHomePage: false,
    displayCreateSuggestionForm: false,
    selectedStore: {
      dataId: '',
      name: '',
      type: '',
      bandingId: 0,
      openHour: '',    
      closeHour: '',
      openHourSun: '',
      closeHourSun: '',
      latitude: 0,
      longitude: 0,
    },
    mimicTooltipsLookup: {},
    displaySchedule: false,
    selectedStoreName:'',
    editableSuggestions: [],
    timeSlotsPerSite: [],
    showConfirmationModal: false,
    savedSuggestionId: '',
    confirmModalMessage: '',
    suggestionEditAction: 0,
    currentCategoryFilter: CategoryFilters.NoFilter,
  });
  const [sortedSuggestions, setSortedSuggestions] = useState<SuggestionStore.Suggestion[]>([]);

  useEffect(() => {
    const { authentication, suggestionState, requestSuggestionsView } = props;
    const { mimicTooltipsLookup, selectedScoringEntity } = state;

    const suggestions = mapSuggestions(suggestionState.suggestionsSchedule);

    if(authentication.scoringEntities) {
      authentication.scoringEntities.forEach((entity) => {
        mimicTooltipsLookup[entity.dataId] = false;
      });
      setState({ ...state, mimicTooltipsLookup, editableSuggestions: suggestions });
    } else {
      setState({ ...state, editableSuggestions: suggestions });
    }

    if(props.suggestionState.isUpdated) {
      requestSuggestionsView(selectedScoringEntity.dataId);
    }
  }, []);

  useEffect(() => {
    const { adminSuggestions } = props.suggestionState;
    const sortedSuggestionArray = sortSuggestionsByDate(adminSuggestions);
    
    if(sortedSuggestionArray){
      const filteredSuggestions = filterSuggestion(sortedSuggestionArray, currentCategoryFilter);
      setSortedSuggestions(filteredSuggestions);
    } else {
      setSortedSuggestions([]);
    }
  }, [props.suggestionState.adminSuggestions, state.currentCategoryFilter]);

  useEffect(() => {
    const { suggestionsSchedule } = props.suggestionState;
    const suggestions = mapSuggestions(suggestionsSchedule);
    setState({ ...state, editableSuggestions: suggestions });
  }, [props.suggestionState.suggestionsSchedule]);

  function mapSuggestions(suggestions: SuggestionStore.Suggestion[]){
    const mappedSuggestions: IEditSuggestionModel[] = [];

    suggestions.forEach(x => {
      mappedSuggestions.push({
        id: x.id,
        siteID: x.siteID, 
        timeSlot: x.timeSlot, 
        editedTimeSlot: x.timeSlot,
        title: x.title, 
        editedTitle: x.title,
        description: x.description,
        editedDescription: x.description,
        type: x.type,
        category: x.category,
        editedCategory: x.category,
        obscurity: x.obscurity,
        editedObscurity: x.obscurity,
        priority: x.priority,
        editedPriority: x.priority,
        editing: false,
      });
    });
    return mappedSuggestions;
  }

  function handleSelectScoringEntity(scoringEntity: AuthenticationStore.ScoringEntity) {
    const { requestAdminSuggestions } = props;

    setState({ ...state, selectedScoringEntity: scoringEntity, displayScoringEntities: false, displaySuggestions: true });

    requestAdminSuggestions(scoringEntity.dataId, DateUtils.getLocalDateString());
  }

  function restoreTooltips() {
    const newMimicTooltipsLookup = { ...state.mimicTooltipsLookup };

    if(mimicTooltipsLookup) {
      Object.keys(newMimicTooltipsLookup).forEach(x => newMimicTooltipsLookup[x] = false);
    }

    return newMimicTooltipsLookup;
  }

  function handleSelectBackBtn() {
    const newMimicTooltipsLookup = restoreTooltips();
    props.cancelMimic();
    setState(
      { 
        ...state,
        selectedScoringEntity: { dataId: '', bandingId: 0, name: '', type: '', closeHour: '', closeHourSun: '', openHour: '', openHourSun: '', latitude: 0, longitude: 0 }, 
        displayScoringEntities: true, displaySuggestions: false, displayHomePage: false,
        displayCreateSuggestionForm: false, displaySchedule: false,
        mimicTooltipsLookup: newMimicTooltipsLookup,
      });
  }

  function handleSelectMimicStoreView(scoringEntity: AuthenticationStore.ScoringEntity) {
    props.mimicStore(scoringEntity.dataId);
    setState({ ...state, displayScoringEntities: false, displayHomePage: true, selectedScoringEntity: scoringEntity });
  }

  function addSuggestion(selectedStore: AuthenticationStore.ScoringEntity) {
    setState({ ...state, selectedStore, displayCreateSuggestionForm: true });
  }

  function removeSuggestionMode() {
    setState({ ...state, displayCreateSuggestionForm: false, displayScoringEntities: true, displayHomePage: false });
  }
  
  function toggleTooltip(site: string, toggle: boolean) {
    const {
      mimicTooltipsLookup,
    } = state;

    const newMimicTooltipsLookup = { ...mimicTooltipsLookup };

    if(mimicTooltipsLookup) {
      newMimicTooltipsLookup[site] = toggle;
    }
    setState({ ...state, mimicTooltipsLookup: newMimicTooltipsLookup });
  } 

  function showSchedule(store: AuthenticationStore.ScoringEntity) {
    const { requestSuggestionsView } = props;
    const newTimeSlots = generateTimeSlots(store);
    
    setState(
      {
        ...state,
        displaySchedule: true,
        selectedStoreName: store.name,
        selectedScoringEntity: store,
        timeSlotsPerSite: newTimeSlots,
      });

    requestSuggestionsView(store.dataId);
  }

  function getTime(timeSlot:string) {
    const time: string[] = timeSlot.split('|')[1].split('');
    time.splice(2, 0, ':');
    return time.join('');
  }

  function sortSuggestionsByDate(adminSuggestions: SuggestionStore.Suggestion[]) {
    return adminSuggestions.length > 0 && adminSuggestions.sort(
      function(a, b) {
        const timeA = new Date(a.dismissed ? a.dismissed : a.seen!);
        const timeB = new Date(b.dismissed ? b.dismissed : b.seen!);

        return timeB.getTime() - timeA.getTime();
      });
  }

  function generateTimeSlots(selectedScoringEntity: AuthenticationStore.ScoringEntity) {
    let startHour = selectedScoringEntity.openHour;
    let endHour = selectedScoringEntity.closeHour;

    if( new Date().getDay() === 6) {
      startHour = selectedScoringEntity.openHourSun;
      endHour = selectedScoringEntity.closeHourSun;
    }

    startHour = startHour.replace(':','');
    endHour = endHour.replace(':','');

    const formatedEndDate = endHour;

    const times = [] as ITimeSlot[];
    for (let i = Number(`${startHour[0]}${startHour[1]}`); i < Number(`${endHour[0]}${endHour[1]}`); i++) {
      for (let j = 0; j < 2; j++) {
        times.push({
          content: `${i < 10 ? '0' + i: i}:${j === 0 ? '00' : 30 * j}`,
          value: `${i < 10 ? '0' + i: i}${j === 0 ? '00' : 30 * j}`,
        });
      }
    }

    if (Number(times[0].value) < Number(startHour)) {
      times.shift();
    }
    if (Number(times[times.length - 1].value) < Number(endHour) && Number(endHour) - Number(times[times.length - 1].value) === 100) {
      times.push({ content: `${endHour[0]}${endHour[1]}:00`, value: (Number(endHour)-30).toString() },
        { content: formatedEndDate, value: endHour },
      );
    }
    return times; 
  }

  function editSuggestion(id: number, timeSlot: string, field: string, value: any) {
    const { editableSuggestions } = state;

    const suggestion = editableSuggestions.find((x: IEditSuggestionModel) => x.id === id && x.timeSlot === timeSlot);

    if(suggestion) {
      switch(field) {
      case 'edit':
        suggestion.editing = value;
        break;
      case 'timeSlot':
        suggestion.editedTimeSlot = value;
        break;
      case 'title':
        suggestion.editedTitle = value;
        break;
      case 'description':
        suggestion.editedDescription = value;
        break;
      case 'category':
        suggestion.editedCategory = value;
        break;
      case 'obscurity':
        suggestion.editedObscurity = value;
        break;
      case 'priority':
        suggestion.editedPriority = value;
        break;
      }

      setState({ ...state, editableSuggestions: [...editableSuggestions] });
    }
  }

  function restoreSuggestionValues(id: number, timeSlot: string, saved: boolean) {
    const { editableSuggestions } = state;

    const suggestion = editableSuggestions.find((x: IEditSuggestionModel) => x.id === id && x.timeSlot === timeSlot);

    if(suggestion) {
      if(saved) {
        suggestion.timeSlot = `${dateFormat(Date.now(), 'GMT:yyyymmdd')}|${suggestion.editedTimeSlot}`;
        suggestion.title = suggestion.editedTitle;
        suggestion.description = suggestion.editedDescription;
        suggestion.category = suggestion.editedCategory;
        suggestion.obscurity = suggestion.editedObscurity;
        suggestion.priority = suggestion.editedPriority;
        suggestion.editing = false;
      } else {
        suggestion.editedTimeSlot = suggestion.timeSlot;
        suggestion.editedTitle = suggestion.title;
        suggestion.editedDescription = suggestion.description;
        suggestion.editedCategory = suggestion.category;
        suggestion.editedObscurity = suggestion.obscurity;
        suggestion.editedPriority = suggestion.priority;
        suggestion.editing = false;
      }

      setState({ ...state, editableSuggestions: [...editableSuggestions] });
    }
  }

  function handleSaveSuggestion(id: string, title: string, timeSlot: string, action: SuggestionEditAction) {
    const actionMessage = action === SuggestionEditAction.Update ? 'update' : 'delete';
    const message = `Are you sure you want to ${actionMessage} suggestion: "${title}" with timeslot: "${getTime(timeSlot)}"?`;

    setState(
      { 
        ...state,
        suggestionEditAction: action, 
        savedSuggestionId: id, 
        showConfirmationModal: true, 
        confirmModalMessage: message, 
      });
  }
  
  function isUsefulApplicable(suggestion: SuggestionStore.Suggestion){ 
    return !(suggestion.category===SuggestionStore.SuggestionCategory.Reminder || suggestion.title === MORNING_BREW_TITLE 
              || suggestion.type === SuggestionStore.SuggestionType.Queue);
  }

  function isCompleteApplicable(suggestion: SuggestionStore.Suggestion){ 
    return suggestion.type !== SuggestionStore.SuggestionType.Queue;
  }

  function filterSuggestion(suggestions:SuggestionStore.Suggestion[], category:string) {
    if (category === CategoryFilters.NoFilter) 
      return suggestions;
   
    return suggestions.filter(s => s.category === category);
  }

  function handleFilterByCategory(category: string) {
    setState({ ...state, currentCategoryFilter: category });
  }

  function onConfirmSuggestionUpdate() {
    const { updateSuggestionManually, deleteSuggestion } = props;
    const { editableSuggestions, savedSuggestionId, selectedScoringEntity, suggestionEditAction } = state;

    const suggestion = editableSuggestions.find((x: IEditSuggestionModel) => (x.id + x.timeSlot) === savedSuggestionId);

    if(suggestion) {
      if(suggestionEditAction === SuggestionEditAction.Update) {
        const updateRequest: SuggestionStore.SuggestionUpdateModel = {
          storeId: selectedScoringEntity.dataId, 
          timeslot: suggestion.timeSlot, 
          title: suggestion.editedTitle,
          description: suggestion.editedDescription,
          category: suggestion.editedCategory,
          type: suggestion.type,
          obscurity: suggestion.editedObscurity,
          priority: suggestion.editedPriority,
          date: dateFormat(Date.now(), 'GMT:yyyy-mm-dd'), 
          id: suggestion.id,
          newTimeSlot: suggestion.editedTimeSlot !== suggestion.timeSlot ? `${dateFormat(Date.now(), 'GMT:yyyymmdd')}|${suggestion.editedTimeSlot}` : undefined,
        };

        updateSuggestionManually(updateRequest);
        restoreSuggestionValues(suggestion.id, suggestion.timeSlot, true);
      } else {

        deleteSuggestion(selectedScoringEntity.dataId, suggestion.timeSlot, suggestion.id);
        editableSuggestions.splice(editableSuggestions.indexOf(suggestion), 1);
      }

      setState({ ...state, showConfirmationModal: false });
    }
  }

  const { authentication, suggestionState } = props;
  const permHelper = new PermissionHelper(authentication.permissions);

  const { 
    selectedScoringEntity, displayScoringEntities, 
    displaySuggestions, displayHomePage, 
    displayCreateSuggestionForm, mimicTooltipsLookup, 
    displaySchedule, selectedStoreName,
    editableSuggestions, timeSlotsPerSite,
    showConfirmationModal, confirmModalMessage, currentCategoryFilter,
  } = state;

  const oneMinute = 1000;

  suggestionState.suggestionsSchedule = suggestionState.suggestionsSchedule.sort((s1, s2) => {
    if(s1.timeSlot === s2.timeSlot) {
      return ((s2.obscurity + s2.priority) - (s1.obscurity + s1.priority));
    }
    return Number(s1.timeSlot.replace('|','')) - Number(s2.timeSlot.replace('|',''));
  });

  return(
    <div className="app">
      <div className="body-wrapper">
        {
          (displaySuggestions || displayCreateSuggestionForm || displaySchedule) && <button onClick={() => handleSelectBackBtn()}
            className='back-btn'>Return to Admin View</button>
        }
        {
          (!displayHomePage || displayCreateSuggestionForm) && !displaySchedule && <h1 className="title">Admin Dashboard</h1>
        }             
        {
          displaySchedule && <h1 className="title">{selectedStoreName}</h1>
        }  
        {
          ( !displayHomePage || displaySchedule ) && suggestionState && suggestionState.isLoading && <Loading />
        }
        {
          displayScoringEntities && !displayCreateSuggestionForm && !displaySchedule &&
             <div className='table-wrapper'>
               <table className='scoring-entities'>
                 <thead>
                   <tr>
                     <th>Store ID</th>
                     <th>Store Name</th>
                     <th>Actions</th>
                   </tr>
                 </thead>
                 <tbody>
                   {
                     authentication.scoringEntities && authentication.scoringEntities.map(s => {
                       return (
                         <tr key={s.dataId}>
                           <td onClick={() => handleSelectScoringEntity(s)}>{s.dataId}</td>
                           <td onClick={() => handleSelectScoringEntity(s)}>{s.name}</td>
                           <td className='actions'>
                             <span onClick={() => handleSelectMimicStoreView(s)}>
                               <img src='assets/mimic-icon.png'
                                 onMouseOver={() => toggleTooltip(s.dataId, true)}
                                 onMouseOut={() => toggleTooltip(s.dataId, false)}/>
                               <ToolTips text="Mimic Mode - View this site's home page"
                                 tipToggled={Object.keys(mimicTooltipsLookup).length > 0 ? mimicTooltipsLookup[s.dataId] : false}
                                 handleParentClick={() => null}
                                 onInputChange={() => null}
                                 hideButton={true}/>
                             </span>
                             {
                               permHelper.HasPermission(Permissions.CreateManualSuggestion) &&
                                <span onClick={() => addSuggestion(s)}>
                                  <svg width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    id="magicoon-Regular"
                                    xmlns="http://www.w3.org/2000/svg"><defs></defs><g id="plus-square-Regular"><path id="plus-square-Regular-2"
                                      data-name="plus-square-Regular"
                                      d="M15,2.25H9A6.758,6.758,0,0,0,2.25,9v6A6.758,6.758,0,0,0,9,21.75h6A6.758,6.758,0,0,0,21.75,15V9A6.758,6.758,0,0,0,15,2.25ZM20.25,15A5.256,5.256,0,0,1,15,20.25H9A5.256,5.256,0,0,1,3.75,15V9A5.256,5.256,0,0,1,9,3.75h6A5.256,5.256,0,0,1,20.25,9Zm-3.5-3a.75.75,0,0,1-.75.75H12.75V16a.75.75,0,0,1-1.5,0V12.75H8a.75.75,0,0,1,0-1.5h3.25V8a.75.75,0,0,1,1.5,0v3.25H16A.75.75,0,0,1,16.75,12Z"/></g></svg>
                                </span>
                             }
                             {
                               permHelper.HasPermission(Permissions.CreateManualSuggestion) &&
                                <span onClick={() => showSchedule(s)}
                                  className="action-icon">
                                  <svg width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g>
                                      <path fill="none"
                                        d="M0 0h24v24H0z"/>
                                      <path d="M7 6V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3v3c0 .552-.45 1-1.007 1H4.007A1.001 1.001 0 0 1 3 21l.003-14c0-.552.45-1 1.007-1H7zm2 0h8v10h2V4H9v2zm-2 5v2h6v-2H7zm0 4v2h6v-2H7z"/>
                                    </g>
                                  </svg>
                                </span>
                             }
                           </td>
                         </tr>
                       );
                     })
                   }
                 </tbody>
               </table>
             </div>
        }
         
        {
          displaySuggestions && !suggestionState.isLoading && suggestionState.adminSuggestions.length > 0 &&
            <>
              <div className='dashboard-filters'>
                <button className='dashboard-filters-btn'
                  onClick={() => handleFilterByCategory(CategoryFilters.NoFilter)}>Show All</button>  
                <button className='dashboard-filters-btn'
                  onClick={() => handleFilterByCategory(SuggestionStore.SuggestionCategory.Reminder)}>Reminders</button> 
              </div>
              <div className='suggestions'>
                
                <div className='table-wrapper'>  
                  <table className='scoring-entities'>
                    <thead>
                      <tr>
                        <th>Store ID</th>
                        <th>Time Shown</th>
                        <th>Last Action</th>
                        <th>Suggestion Title</th>
                        <th>Suggestion Description</th>
                        <th>Useful</th>
                        <th>Actioned</th>
                      </tr>
                    </thead>
                    <tbody>
                      {

                        sortedSuggestions && sortedSuggestions.map((suggestion, index) => {
                          return (
                            <tr key={index}>
                              <td>{selectedScoringEntity.dataId}</td>
                              <td>{dateFormat(suggestion.seen, 'GMT:dd-mm-yyyy HH:MM:ss')}</td>
                              <td>{dateFormat(suggestion.dismissed ? suggestion.dismissed : suggestion.seen, 'GMT:dd-mm-yyyy HH:MM:ss')}</td>
                              <td className='suggestion-description'>{suggestion.title}</td>
                              <td className='suggestion-description'>{suggestion.description}</td>
                              <td>
                                {
                                  suggestion.helpful 
                                    ? <img src='/assets/circle-green.svg'/> 
                                    : suggestion.helpful === false 
                                      ? <img src='/assets/circle-red.svg'/> 
                                      : !isUsefulApplicable(suggestion) 
                                        ? <p className='usefulText'> N/A </p>
                                        : <img src='/assets/circle-gray.svg'/>}
                              </td>
                              <td>
                                {
                                  suggestion.complete 
                                    ? 
                                    <img src='/assets/circle-green.svg'/> 
                                    : 
                                    suggestion.complete === false 
                                      ? 
                                      <img src='/assets/circle-red.svg'/> 
                                      : !isCompleteApplicable(suggestion)
                                        ? <p className='usefulText'> N/A </p>
                                        :<img src='/assets/circle-gray.svg'/>}
                              </td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </>
        }
        {
          displaySuggestions && !suggestionState.isLoading && suggestionState.adminSuggestions.length === 0 &&
            <h3>{'There aren\'t any seen suggestions yet for this site'}</h3>
        }
        {
          displayHomePage && !displayCreateSuggestionForm && !displayScoringEntities && !displaySchedule &&
              <Animated
                key='default'
                animationIn="fadeIn"
                animationOut="zoomOut"
                animationInDuration={oneMinute}
                animationOutDuration={oneMinute}
                isVisible={true}>
                <HomePage selectedScoringEntity = {state.selectedScoringEntity.dataId}
                  adminMode={true}
                  handleAdminViewBack = {handleSelectBackBtn}/>
              </Animated>
            
        }
        {
          displayCreateSuggestionForm && <SuggestionForm toogleIsSuggestionMode={() => removeSuggestionMode()}
            selectedStore={state.selectedStore} />
        }

        {displaySchedule && !suggestionState.isLoading && 
           <div className='table-wrapper'>
             <table className='scoring-entities'>
               <thead>
                 <tr>
                   <th>Time</th>
                   <th>Title</th>
                   <th>Description</th>
                   <th>Category</th>
                   <th>Obscurity</th>
                   <th>Priority</th>
                   <th colSpan={2}>Actions</th>
                 </tr>
               </thead>
               <tbody>
                 {
                   editableSuggestions && editableSuggestions.map((s, index) => {
                     return (
                       <tr key={index}>
                         <td>{ s.editing ? 
                           <select name="timeSlot"
                             id="timeSlot"
                             onChange={(e) => editSuggestion(s.id, s.timeSlot, 'timeSlot', e.target.value)}>
                             <option value={s.timeSlot}>{getTime(s.timeSlot)}</option>
                             {timeSlotsPerSite.map((time: ITimeSlot) => {
                               return (<option value={time.value}
                                 key={time.value}>{time.content}</option>);
                             })}
                           </select>
                           : 
                           getTime(s.timeSlot)}
                         </td>
                         <td>{ s.editing ? 
                           <input type='text'
                             onChange={(e) => editSuggestion(s.id, s.timeSlot, 'title', e.target.value)}
                             value={s.editedTitle}/> : s.title}
                         </td>
                         <td>{ s.editing ? 
                           <input type='text'
                             onChange={(e) => editSuggestion(s.id, s.timeSlot, 'description', e.target.value)}
                             value={s.editedDescription}/> : s.description}
                         </td>
                         <td>{ s.editing ? 
                           <input type='text'
                             onChange={(e) => editSuggestion(s.id, s.timeSlot, 'category', e.target.value)}
                             value={s.editedCategory}/> : s.category}
                         </td>
                         <td>{ s.editing ? 
                           <input type='number'
                             onChange={(e) => editSuggestion(s.id, s.timeSlot, 'obscurity', e.target.value)}
                             max={10}
                             min={1}
                             value={s.editedObscurity}/> : s.obscurity}
                         </td>
                         <td>{ s.editing ? 
                           <input type='number'
                             onChange={(e) => editSuggestion(s.id, s.timeSlot, 'priority', e.target.value)}
                             max={10}
                             min={1}
                             value={s.editedPriority}/> : s.priority}
                         </td>
                         <td onClick={() => s.editing
                           ? 
                           restoreSuggestionValues(s.id, s.timeSlot, false)
                           : 
                           editSuggestion(s.id, s.timeSlot, 'edit', true)}>
                           { s.editing ? 'Cancel' : <img className='action-img'
                             src='/assets/edit-icon.svg' />}
                         </td>
                         <td onClick={() => s.editing
                           ?
                           handleSaveSuggestion(s.id + s.timeSlot, s.title, s.timeSlot, SuggestionEditAction.Update)
                           :
                           handleSaveSuggestion(s.id + s.timeSlot, s.title, s.timeSlot, SuggestionEditAction.Delete)}>
                           {s.editing ? 'Save' : <img className='action-img'
                             src='/assets/bin.png' />}
                         </td>
                       </tr>
                     );
                   })
                 }
               </tbody>
             </table>
           </div>
        }
        {
          displaySchedule && !suggestionState.isLoading && suggestionState.suggestionsSchedule && suggestionState.suggestionsSchedule.length === 0 &&
            <h3>{'There aren\'t any seen suggestions yet for this store'}</h3>
        }
        { showConfirmationModal &&
            <ConfirmationModal
              onCancel={() => setState({ ...state, showConfirmationModal: false })}
              onContinue={() => onConfirmSuggestionUpdate()}
              cancelText='Cancel'
              continueText='Yes'
              description={confirmModalMessage}
              title='Are you sure?'
            />
        }
      </div>
    </div>
  );
}

export default connector(WithRouter(Admin));
