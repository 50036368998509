/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppThunkAction } from '../index';
import { Action, Reducer, AnyAction } from 'redux';
import { Trend } from '../MorningBrew/MorningBrew';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface DailyForecastState {
    isLoading: boolean;
    seasonalContentLoading: boolean,
    isErrored: boolean;
    errorMessage: string;
    isUpdated: boolean;

    listMetrics: ListMetric[];
    trends: Trend[];
    dailySuggestions: DailySuggestion[];
    suggestedBreakTimes: String[],
    seasonalContent?: SeasonalContent[];
}

export interface ListMetric {
    metric: string;
    values: ListMetricItem[]
}

export interface SeasonalContent {
    title: string;
    description: string;
    featuredImage: string;
    contentImages: string[];
    validFrom: Date;
    validTo: Date;
    type : string;
}

export interface ListMetricItem {
    itemNumber: string;
    itemDescription: string;
    itemQty: number;
    uom: string;
    numOfDaysBeforeRunOut: number
}

export enum UoMTypes {
  Grams = 'gm',
  Milliliters ='ml',
  Each = 'each',
  Eaches = 'eaches'
}

export interface DailySuggestion {
    id: string,
    title: string;
    description: string;
    priority: number;
}

// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something
// that is going to happen.

export interface RequestDailyForecastAction extends AnyAction {
    type: 'REQUEST_DAILY_FORECAST';
}

export interface ReceiveDailyForecastAction extends AnyAction {
    type: 'RECEIVE_DAILY_FORECAST';
    payload: any;
}

export interface FailedDailyForecastAction extends AnyAction {
    type: 'FAILED_DAILY_FORECAST';
}

export interface RequestSeasonalContentAction extends AnyAction {
    type: 'REQUEST_SEASONAL_CONTENT';
}

export interface ReceiveSeasonalContentAction extends AnyAction {
    type: 'RECEIVE_SEASONAL_CONTENT';
    payload: any;
}

export interface FailedSeasonalContentAction extends AnyAction {
    type: 'FAILED_SEASONAL_CONTENT';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type'
// properties contain one of the
// declared type strings (and not any other arbitrary string).
export type AreaAction = RequestDailyForecastAction | ReceiveDailyForecastAction | FailedDailyForecastAction
                        | RequestSeasonalContentAction | ReceiveSeasonalContentAction | FailedSeasonalContentAction;
                         
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger
// a state transition.
// They don't directly mutate state, but they can have external side-effects
// (such as loading data).

export const actionCreators = {
  requestDailyForecast: (storeId: string, date: string):
    AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.dailyForecast && appState.dailyForecast.isLoading === false) {
      dispatch({ 
        type: 'REQUEST_DAILY_FORECAST',
        http: {
          verb: 'GET',
          endpoint: `/api/statistics/dailyForecast?reportingEntityID=${storeId}&reportingDate=${date}`,
          successAction: 'RECEIVE_DAILY_FORECAST',
          failureAction: 'FAILED_DAILY_FORECAST',
        }, 
      });
    }
  },

  requestSeasonalContent: (localDate: string):
    AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.dailyForecast.seasonalContentLoading === false) {
      dispatch({ 
        type: 'REQUEST_SEASONAL_CONTENT',
        http: {
          verb: 'GET',
          headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
          endpoint: `/api/content/seasonalcontent?localDate=${localDate}`,
          successAction: 'RECEIVE_SEASONAL_CONTENT',
          failureAction: 'FAILED_SEASONAL_CONTENT',
        }, 
      });
    }
  },
};

// REDUCER - For a given state and action, returns the new state. To support time travel,
// this must not mutate the old state.

const unloadedState: DailyForecastState = {
  errorMessage: '',
  isErrored: false,
  isLoading: false,
  seasonalContentLoading: false,
  isUpdated: false,
  listMetrics: [],
  trends: [],
  dailySuggestions: [],
  suggestedBreakTimes: [],
  seasonalContent: undefined,
};

export const reducer: Reducer<DailyForecastState> = (state: DailyForecastState | undefined,
  incomingAction: Action):DailyForecastState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as AreaAction;
  switch (action.type) {
  case 'REQUEST_DAILY_FORECAST':
    return {
      ...unloadedState,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
    };
  case 'REQUEST_SEASONAL_CONTENT': 
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      seasonalContentLoading: true,
      isLoading: state.isLoading,
    };
  case 'RECEIVE_DAILY_FORECAST':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      listMetrics: action.payload.listMetrics || [],
      trends: action.payload.trends || [],
      dailySuggestions: action.payload.dailySuggestions || [],
      suggestedBreakTimes:  action.payload.suggestedBreakTimes || [],
    };
  case 'RECEIVE_SEASONAL_CONTENT':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: state.isLoading,
      seasonalContentLoading: false,
      seasonalContent: action.payload, 
    };
  case 'FAILED_DAILY_FORECAST':
    return {
      ...unloadedState,
      errorMessage: 'An error occurred while receiving the data.',
      isErrored: true,
      isLoading: false,
    };
  case 'FAILED_SEASONAL_CONTENT': 
    return {
      ...state,
      isErrored: true,
      isLoading: state.isLoading,
      seasonalContentLoading: false,
    };
  default:
    return state;
  }
};

