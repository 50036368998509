type LoginProps = {
    placeholderText: string,
    inputType: string,
    required?: boolean,
    onChange: Function,
    value?: string,
    valid?: boolean,
    name:string,
    label:string,
    maxLength?: number,
    min?: number | string,
    max?: number | string,

}

function Input (props: LoginProps) {
  const {
    label, name, valid, value, maxLength, onChange, required, inputType, placeholderText, min, max,
  } = props;

  return (
    <label
      htmlFor={name}
      className="input"
    >
      {label}
      <input
        name={name}
        type={inputType}
        placeholder={placeholderText}
        onChange={(e) => onChange(e.target.value)}
        maxLength = {maxLength}
        value={value}
        required={required}
        className={valid ? 'invalid' : ''}
        min = {min}
        max = {max}
      />
    </label>
  );
}

export default Input;