import { useState } from 'react';
import {
  ActionAnimations,
  SwipeableList,
  SwipeableListItem,
} from '@sandstreamdev/react-swipeable-list';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const _displaySwipeIconsThreshold = 15;
const _displaySwipeTextThreshold = 30;

export type SwipeableContent = {
  leftContent: SwipeableSwipeContent,
  rightContent: SwipeableSwipeContent,
}

export type SwipeableSwipeContent = {
  text?: string,
  imgSrc?: string
}

type Props = {
  body: JSX.Element,
  swipeContent: SwipeableContent,
  onSwipeProgress: Function,
      /**
   * When the swipe threshold is crossed the `action` function will be called and it will in turn call this function
   */
  onSwipeThresholdCrossed: Function,
      /**
   * This function will be called when the swipe ends or the user lifts the finger from the screen
   */
  onSwipeEnd: Function,
    /**
   * The `action` callback function will be run when the swipe is done beyond this value.
   */
  actionThreshold: number,
      /**
   * The component will be moved out of the screen when the swipe is done beyond this value.
   */
  removeThreshold: number,
  blockSwipe?: boolean,
  className?: string,
}

export const Swipeable = (props: Props) => {
  const [displaySwipeIcons, setDisplaySwipeIcons] = useState(false);
  const [displaySwipeText, setDisplaySwipeText] = useState(false);
  const [swipeProgress, setSwipeProgress] = useState(0);

  const getCardAnimationType = () => {
    return swipeProgress < props.removeThreshold ? ActionAnimations.RETURN : ActionAnimations.REMOVE;
  };
  
  /**
   * We need the progress to be stored in the state for the `actionAnimation` but also sent back to the parent component for other behaviours
   */
  const onSwipeProgress = (progress: number) => {
    setSwipeProgress(progress);
    if (progress >= _displaySwipeIconsThreshold) {
      setDisplaySwipeIcons(true);
    }
    if (progress >= _displaySwipeTextThreshold) {
      setDisplaySwipeText(true);
    }
    props.onSwipeProgress(progress);
  };


  return (
    <SwipeableList               
      swipeStartThreshold={0.1}>
      {({ className }: {className: string}) => (
        <TransitionGroup
          className={`${className} ${props.className ?? ''}`}
          enter={true}
          exit={true}
        >
          <CSSTransition
            classNames="my-node"
            timeout={3500}
          >
            <SwipeableListItem
              blockSwipe={props.blockSwipe}
              threshold={props.actionThreshold}
              swipeLeft={{
                content: 
                  <div className='swipe-content'>
                    {
                      props.swipeContent.leftContent.imgSrc && 
                          <img className={displaySwipeIcons ? 'visible' : ''}
                            src={props.swipeContent.leftContent.imgSrc}/>
                    }
                    <div className={`content-text ${displaySwipeText ? 'visible' : ''}`}>{props.swipeContent.leftContent.text}</div>
                  </div>,
                actionAnimation: getCardAnimationType(),
                action: () => props.onSwipeThresholdCrossed(false),
              }}
              swipeRight={{
                content: 
                  <div className='swipe-content'>
                    {
                      props.swipeContent.rightContent.imgSrc &&
                          <img className={displaySwipeIcons ? 'visible' : ''}
                            src={props.swipeContent.rightContent.imgSrc}/>
                    }
                    <div className={`content-text ${displaySwipeText ? 'visible' : ''}`}>{props.swipeContent.rightContent.text}</div>
                  </div>,
                actionAnimation: getCardAnimationType(),
                action: () => props.onSwipeThresholdCrossed(true),
              }}
              onSwipeProgress={(progress) => onSwipeProgress(progress)}
              onSwipeEnd={() => props.onSwipeEnd()}
            >                             
              {
                props.body  
              }
            </SwipeableListItem>
          </CSSTransition>
        </TransitionGroup>
      )}
    </SwipeableList>
  );
};