interface IProps {
    title?: string;
    description?: string;
    onContinue: Function;
    continueText: string;
    onCancel?: Function;
    cancelText?: string;
}

function ConfirmationModal(props: IProps) {
  return (
    <div className="modal-wrapper">
      <div className="modal-blocker ui-toolkit">
        <div className="modal-content">
          <form>
            { props.title &&
                <div className="modal-title">
                  <h2>{props.title}</h2>
                </div>
            }
            { props.description &&
            <div className="modal-body">
              {props.description}
            </div>
            }
            <div className="modal-buttons">
              <button
                type="button"
                className="action"
                onClick={() => props.onContinue()}
              >
                {props.continueText}
              </button>
              {(props.onCancel && props.cancelText) &&
                <button
                  type="button"
                  className="action negative"
                  onClick={() => props.onCancel && props.onCancel()}
                >
                  {props.cancelText}
                </button>
              }
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationModal;
