import { ApexOptions } from 'apexcharts';

export const weatherForecastOptions: ApexOptions = {
  chart: {
    stacked: true,
    background: '#fff',
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.4,
      opacityTo: 0.4,
      stops: [100, 100, 100],
    },
  },
  dataLabels: {
    enabled: true,
    textAnchor: 'middle',
    offsetY: -5,
    style: {
      fontSize: '14px',
      fontFamily: 'Helvetica, Arial, sans-serif',
      fontWeight: 'bold',
      colors: ['#000'],
    },
    background: {
      enabled: false,
    },
  },
  stroke: {
    curve: 'smooth', 
  },
  
  grid: {
    show: false,
  },

  xaxis: {
    labels: {
      show: true,
    },

    categories: [''],

    axisBorder: {
      show: false,
    },
  },
  
  plotOptions: {
    area: {
      fillTo: 'end',
    },
  },

  yaxis: {
    labels: {
      show: false,
    },

    axisBorder: {
      show: false,
    },
  },

  legend: {
    show: false,
    position: 'bottom',
    onItemHover: {
      highlightDataSeries: true,
    },
    showForSingleSeries: true,
  },
  colors: ['rgba(255, 204, 0, 0.5)'],
};

export const weatherForecastSeries: StatisticsData[] = [
  {
    name: 'Weather Data',
    data: [],
  },
];

export interface StatisticsData {
  name: string,
  data: number[]
}
