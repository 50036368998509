/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppThunkAction } from '../index';
import { Action, Reducer, AnyAction } from 'redux';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface TrainingAreaState {
    isLoading: boolean;
    isErrored: boolean;
    errorMessage: string;
    isUpdated: boolean;
    isDeleted: boolean;
    areas: TrainingArea[],
    items: TrainingAreaItem[],
    totalRecords: number,
    item: TrainingAreaItem | undefined
}

export interface TrainingArea {
  id: number;
  name: string;
  thumbnail: string;
}

export interface TrainingAreaItem {
  id: number,
  name: string,
  trainingAreaId: number,
  trainingAreaName: string,
  thumbnail: string, 
  content: string,
}


// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something
// that is going to happen.

export interface RequestTrainingAreas extends AnyAction {
    type: 'REQUEST_TRAINING_AREAS';
}

export interface ReceiveTrainingAreas extends AnyAction {
    type: 'RECEIVE_TRAINING_AREAS';
    payload: any;
}

export interface FailedTrainingAreas extends AnyAction {
    type: 'FAILED_TRAINING_AREAS';
}

export interface RequestTrainingAreaItems extends AnyAction {
    type: 'REQUEST_TRAINING_AREA_ITEMS';
}

export interface ReceiveTrainingAreaItems extends AnyAction {
    type: 'RECEIVE_TRAINING_AREA_ITEMS';
    payload: any;
}

export interface FailedTrainingAreaItems extends AnyAction {
    type: 'FAILED_TRAINING_AREA_ITEMS';
}

export interface RequestTrainingAreaItemsById extends AnyAction {
    type: 'REQUEST_TRAINING_AREA_ITEMS_BY_ID';
}

export interface ReceiveTrainingAreaItemsById extends AnyAction {
    type: 'RECEIVE_TRAINING_AREA_ITEMS_BY_ID';
    payload: any;
}

export interface FailedTrainingAreaItemsById extends AnyAction {
    type: 'FAILED_TRAINING_AREA_ITEMS_BY_ID';
}

export interface RequestTrainingAreaItem extends AnyAction {
    type: 'REQUEST_TRAINING_AREA_ITEM';
}

export interface ReceiveTrainingAreaItem extends AnyAction {
    type: 'RECEIVE_TRAINING_AREA_ITEM';
    payload: any;
}

export interface ReceiveUpdateTrainingAreaItem extends AnyAction {
    type: 'RECEIVE_UPDATE_TRAINING_AREA_ITEM';
    payload: any;
}

export interface FailedTrainingAreaItem extends AnyAction {
    type: 'FAILED_TRAINING_AREA_ITEM';
}

export interface UpdateTrainingAreaItem extends AnyAction {
    type: 'REQUEST_UPDATE_TRAINING_AREA_ITEM';
}

export interface FailedUpdateTrainingAreaItem extends AnyAction {
    type: 'FAILED_UPDATE_TRAINING_AREA_ITEM';
}

export interface ReceiveDeleteTrainingAreaItem extends AnyAction {
    type: 'RECEIVE_DELETE_TRAINING_AREA_ITEM';
}


// Declare a 'discriminated union' type. This guarantees that all references to 'type'
// properties contain one of the
// declared type strings (and not any other arbitrary string).
export type AreaAction = RequestTrainingAreas | ReceiveTrainingAreas | FailedTrainingAreas |
                          RequestTrainingAreaItems | ReceiveTrainingAreaItems | FailedTrainingAreaItems |
                          RequestTrainingAreaItemsById | ReceiveTrainingAreaItemsById | FailedTrainingAreaItemsById |
                          RequestTrainingAreaItem | ReceiveTrainingAreaItem | FailedTrainingAreaItem | 
                          UpdateTrainingAreaItem | FailedUpdateTrainingAreaItem | ReceiveUpdateTrainingAreaItem |
                          ReceiveDeleteTrainingAreaItem;
                         
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger
// a state transition.
// They don't directly mutate state, but they can have external side-effects
// (such as loading data).

export const actionCreators = {
  requestTrainingAreas: ():
    AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.trainingArea && appState.trainingArea.isLoading === false) {
      dispatch({ 
        type: 'REQUEST_TRAINING_AREAS',
        http: {
          verb: 'GET',
          endpoint: '/api/content/getTrainingAreas',
          successAction: 'RECEIVE_TRAINING_AREAS',
          failureAction: 'FAILED_TRAINING_AREAS',
        }, 
      });
    }
  },
  requestTrainingAreaItems: (page: number, pageSize: number):
    AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.trainingArea && appState.trainingArea.isLoading === false) {
      dispatch({ 
        type: 'REQUEST_TRAINING_AREA_ITEMS',
        http: {
          verb: 'GET',
          endpoint: `/api/content/getTrainingAreaItems?page=${page}&pageSize=${pageSize}`,
          successAction: 'RECEIVE_TRAINING_AREA_ITEMS',
          failureAction: 'FAILED_TRAINING_AREA_ITEM',
        }, 
      });
    }
  },

  requestTrainingAreaItemsById: (trainingAreaId: number):
    AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.trainingArea && appState.trainingArea.isLoading === false) {
      dispatch({ 
        type: 'REQUEST_TRAINING_AREA_ITEMS_BY_ID',
        http: {
          verb: 'GET',
          endpoint: `/api/content/getTrainingAreaItemsById?trainingAreaId=${trainingAreaId}`,
          successAction: 'RECEIVE_TRAINING_AREA_ITEMS_BY_ID',
          failureAction: 'FAILED_TRAINING_AREA_ITEM_BY_ID',
        }, 
      });
    }
  },

  requestTrainingAreaItem: (id: number):
    AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.trainingArea && appState.trainingArea.isLoading === false) {
      dispatch({ 
        type: 'REQUEST_TRAINING_AREA_ITEM',
        http: {
          verb: 'GET',
          endpoint: `/api/content/getTrainingAreaItem?id=${id}`,
          successAction: 'RECEIVE_TRAINING_AREA_ITEM',
          failureAction: 'FAILED_TRAINING_AREA_ITEM',
        }, 
      });
    }
  },
  addTrainingAreaItem: (item: TrainingAreaItem):
    AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.trainingArea && appState.trainingArea.isLoading === false) {
      dispatch({ 
        type: 'REQUEST_UPDATE_TRAINING_AREA_ITEM',
        http: {
          verb: 'POST',
          endpoint: '/api/content/AddTrainingAreaItem',
          successAction: 'RECEIVE_UPDATE_TRAINING_AREA_ITEM',
          failureAction: 'FAILED_UPDATE_TRAINING_AREA_ITEM',
          body: {
            ...item,
          },
        }, 
      });
    }
  },
  updateTrainingAreaItem: (item: TrainingAreaItem):
    AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.trainingArea && appState.trainingArea.isLoading === false) {
      dispatch({ 
        type: 'REQUEST_UPDATE_TRAINING_AREA_ITEM',
        http: {
          verb: 'PUT',
          endpoint: '/api/content/updateTrainingAreaItem',
          successAction: 'RECEIVE_UPDATE_TRAINING_AREA_ITEM',
          failureAction: 'FAILED_UPDATE_TRAINING_AREA_ITEM',
          body: {
            ...item,
          },
        }, 
      });
    }
  },
  deleteTrainingAreaItem: (id: number):
    AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.trainingArea && appState.trainingArea.isLoading === false) {
      dispatch({ 
        type: 'REQUEST_UPDATE_TRAINING_AREA_ITEM',
        http: {
          verb: 'DELETE',
          endpoint: `/api/content/deleteTrainingAreaItem?id=${id}`,
          successAction: 'RECEIVE_DELETE_TRAINING_AREA_ITEM',
          failureAction: 'FAILED__UPDATE_TRAINING_AREA_ITEM',
        }, 
      });
    }
  },
};

// REDUCER - For a given state and action, returns the new state. To support time travel,
// this must not mutate the old state.

const unloadedState: TrainingAreaState = {
  errorMessage: '',
  isErrored: false,
  isLoading: false,
  isUpdated: false,
  isDeleted: false,
  areas:[],
  items: [],
  item: undefined,
  totalRecords: 0,
};

export const reducer: Reducer<TrainingAreaState> = (state: TrainingAreaState | undefined,
  incomingAction: Action):TrainingAreaState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as AreaAction;
  switch (action.type) {
  case 'REQUEST_TRAINING_AREAS':
  case 'REQUEST_TRAINING_AREA_ITEMS':
  case 'REQUEST_TRAINING_AREA_ITEM':
  case 'REQUEST_TRAINING_AREA_ITEMS_BY_ID':

  
    return {
      ...unloadedState,
      errorMessage: '',
      isErrored: false,
      isDeleted: false,
      isLoading: true,
    };
  case 'REQUEST_UPDATE_TRAINING_AREA_ITEM': {
    return {
      ...unloadedState,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
      item: state.item,
      areas: state.areas,
      items: state.items,
    };
  }
  case 'RECEIVE_TRAINING_AREAS':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      areas: action.payload,
    };
  case 'RECEIVE_TRAINING_AREA_ITEMS': 
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      items: action.payload.data,
      totalRecords: action.payload.totalRecords,
      isDeleted: false,
    };
  case 'RECEIVE_TRAINING_AREA_ITEMS_BY_ID':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      items: action.payload,
      isDeleted: false,
    };
  case 'RECEIVE_TRAINING_AREA_ITEM': 
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      item: action.payload,
    };
  case 'RECEIVE_UPDATE_TRAINING_AREA_ITEM':
    return {
      ...unloadedState,
      errorMessage: '',
      isErrored: false,
      isUpdated: true,
      isLoading: false,
      item: state.item,
      areas: state.areas,
    };
  case 'RECEIVE_DELETE_TRAINING_AREA_ITEM': 
    return {
      ...unloadedState,
      errorMessage: '',
      isErrored: false,
      isUpdated: false,
      isLoading: false,
      isDeleted: true,
      items: state.items,
    };
  case 'FAILED_TRAINING_AREAS':
  case 'FAILED_TRAINING_AREA_ITEMS':
  case 'FAILED_TRAINING_AREA_ITEMS_BY_ID':
  case 'FAILED_TRAINING_AREA_ITEM':
    return {
      ...unloadedState,
      errorMessage: 'An error occurred while receiving the data.',
      isErrored: true,
      isLoading: false,
      isDeleted: false,
    };
  case 'FAILED_UPDATE_TRAINING_AREA_ITEM':
    return {
      ...unloadedState,
      errorMessage: 'The data sent is not in an appropriate format.',
      isErrored: true,
      isLoading: false,
      isDeleted: false,
    };
  default:
    return state;
  }
};

