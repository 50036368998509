import React, { useEffect } from 'react';
import TrainingAreaItemCard from './TrainingAreaItemCard';
import { ApplicationState } from '../../store';
import * as TrainingAreaStore from '../../store/TrainingArea/TrainingArea';
import { Loading } from '../Shared/Loading/Loading';
import { useParams } from 'react-router-dom';
import { ConnectedProps, connect } from 'react-redux';

const mapState = (state: ApplicationState) => ({
  trainingArea: state.trainingArea,
});

const mapDispatch = {
  ...TrainingAreaStore.actionCreators,
};
const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IProps = PropsFromRedux;

type ItemsParams = {
  trainingAreaId: string;
};

const TrainingCatalogPage = (props: IProps) => {
  const { trainingArea, requestTrainingAreaItemsById } = props;
  const { trainingAreaId } = useParams<ItemsParams>();

  useEffect(() => {
    if (trainingAreaId && parseInt(trainingAreaId) > 0) {
      requestTrainingAreaItemsById(parseInt(trainingAreaId));
    }
  }, [trainingAreaId]);

  return (
    <div className="app">
      <div className="body-wrapper">
        <div className="training-container">
          {trainingArea.isLoading && <Loading />}

          <h1 className='title'>{trainingArea.items[0]?.trainingAreaName}</h1>

          {!trainingArea.isLoading && trainingArea.items && (
            <>
              <p>{trainingArea.items.length} results</p>
              <div className="training-card-container-catalog">
                {!trainingArea.isLoading && trainingArea.items && trainingArea.items.length === 0 && (
                  <h3>No items available</h3>
                )}
                {!trainingArea.isLoading &&
              trainingArea.items &&
              trainingArea.items.length > 0 &&
              trainingArea.items.map((item) => (
                <TrainingAreaItemCard key={item.id}
                  item={item} />
              ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default connector(TrainingCatalogPage);
