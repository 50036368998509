/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthManager } from '../../AuthManager';

export type MiddlewareFunction = (store: any) => (next: any) => (action: any) => any;

export const HttpMiddleware: MiddlewareFunction = () => (next) => (action) => {
  const authManager = new AuthManager();
  
  if (action.http) {
    authManager.generateAccessToken(() => {
      const token = authManager.getAccessToken();
      
      const actionInfo = action.http;
      const defaultHeaders: any = { 'Content-Type': 'application/json' };

      defaultHeaders.Authorization = token;

      const fetchOptions: any = {
        method: actionInfo.verb,
        headers: { ...defaultHeaders, ...actionInfo.headers },
      };

      if (actionInfo.body) {
        fetchOptions.body = JSON.stringify(actionInfo.body);
      }

      next({
        type: action.type,
        payload: action.payload,
      });

      fetch(actionInfo.endpoint, fetchOptions)
        .then((response) => {
          const responseType: string = (response.headers && response.headers.get('content-type') && response.headers.get('content-type')) || '';

          if (!response.ok) {
            if (response.status === 401) {
              return next(action);
            }

            if(response.status === 400) {
              throw new Error (
                'The data sent is not in an appropriate format.',
              );
            }

            if (response.status === 403) {
              throw new Error(
                'You do not currently have access to this area. Please contact your administrator.',
              );
            }
            
            if (response.status === 404) {
              throw new Error(
                'There is currently no data available.',
              );
            }

            if (responseType.indexOf('text/plain') > -1) {
              return response.text();
            }

            return response.json();
          } 
          
          if (response.headers.get('content-length') === '0') {
            return {};
          }

          if (responseType.indexOf('application/pdf') > -1) {
            return response.blob();
          }

          return response.json();
        })
        .then((data) => {
          if (data && !data.payload) {
            if (typeof data === 'string') {
              throw new Error(data);
            } else if (data.message) {
              throw new Error(data.message);
            } else if (data.errorMessage) {
              throw new Error(data.errorMessage);
            } else {
              next({
                type: actionInfo.successAction,
                payload: data,
              });
            }
          }
        })
        .catch((error) => next({
          type: actionInfo.failureAction,
          payload: error,
        }));
    
    });
  } else {
    return next(action);
  }
};