import { buildStyles, CircularProgressbar, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

type Props = {
    maxValue?: number,
    value: number,
    className?: string,
    header?: string,
    showPercentage?: boolean,
    showValue?: boolean,
    title?: string,
    basic?: boolean
    lowerIsBetter?: boolean,
}
  


function CircularProgressBar(props: Props) {
  const maxValue = props.maxValue ?? 100;
  function getColor (value: number, maxValue: number, lowerIsBetter?: boolean){
    const percentage = value / maxValue * 100;
    const green = '#46AF3A';
    const orange = '#CC9D27';
    const red = '#CC4729';

    if(lowerIsBetter){
      if(percentage > 99){
        return red;
      }
      return green;
    }

    if(percentage > 99){
      return green;
    }
    if (percentage > 34){
      return orange;
    }
    return red;
  }

  return (
    <div className={`circular-bar ${props.className ?? '' }`}>
      { props.basic ?
        <CircularProgressbar value={props.value} 
          maxValue={maxValue} 
          styles={buildStyles({
            pathColor: getColor(props.value, maxValue, props.lowerIsBetter),
          })}
        />
        :
        <CircularProgressbarWithChildren value={props.value}
          maxValue={maxValue}
          styles={buildStyles({
            pathColor: getColor(props.value, maxValue, props.lowerIsBetter),
          })}>
          <div>
            {props.header}
          </div>
          <div className={'circular-bar-value'}>
            { props.showPercentage &&
              `${props.value}%`
            }
            { props.showValue &&
              props.value
            }
          </div>
          <div className={'circular-bar-title'}>
            {props.title}
          </div>
        </CircularProgressbarWithChildren>
      }
    </div>
  );
}

export default CircularProgressBar;