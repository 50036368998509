import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { ApplicationState } from '../../store';
import * as NotificationStore from '../../store/Notification/Notification';
import { ConnectedProps, connect } from 'react-redux';
import NotificationPanel from './NotificationPanel';

const mapState = (state: ApplicationState) => ({
  notificationState: state.notification,
});

const mapDispatch = {
  ...NotificationStore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Notification: React.FC<PropsFromRedux> = ({
  hasNewNotifications,
  notificationState,
}) => {

  const [showPanel, setShowPanel] = useState(false);
  const notificationRef = useRef<HTMLDivElement>(null);

  const notificationIntervalMinutes = 10;
  const notificationIntervalMilliseconds = notificationIntervalMinutes * 60 * 1000;

  useEffect(() => {
    hasNewNotifications();
    const interval = setInterval(() => {
      hasNewNotifications();
    }, notificationIntervalMilliseconds);

    return () => {
      clearInterval(interval);
    };
  }, [hasNewNotifications]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target as Node) &&
        !(event.target as HTMLElement).classList.contains('bell-icon')
      ) {
        setShowPanel(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handlePanelToggle = () => {
    if (!showPanel) {
      hasNewNotifications();
    }
    setShowPanel(!showPanel);
  };

  const handleClosePanel = () => {
    setShowPanel(false);
  };

  return (
    <div className="notification-container"
      ref={notificationRef}>
      <div className="notification-bell"
        onClick={handlePanelToggle}>
        <FontAwesomeIcon
          icon={faBell}
          className={`bell-icon${notificationState.hasNewNotifications ? ' active' : ''}`}
          size='lg'
          style={{ color: 'white' }}
        />
        {notificationState.hasNewNotifications && <span className="notification-dot"></span>}
      </div>
      {showPanel && (
        <NotificationPanel
          handleClosePanel={handleClosePanel}
        />
      )}
    </div>
  );
};

export default connector(Notification);
