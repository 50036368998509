function FooterBar () {
  return(
    <footer className="footer-bar">
      <div className="inner-container">
        <p className="header-text">© Orderly {new Date().getFullYear()} &emsp;&emsp;For help and support please contact support@orderly.io</p>
      </div>
    </footer>
  );
}

export default FooterBar;
