/* eslint-disable @typescript-eslint/no-explicit-any */
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../../../store';
import * as SuggestionStore from  '../../../store/Suggestion/Suggestion';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Permissions } from '../../../store/Authentication/Authentication';
import PermissionHelper from '../../../helpers/PermissionHelper';
import Notification from '../../Notification/Notification';

const mapState = (state: ApplicationState) => ({
  authentication: state.authentication,
});

const mapDispatch = {
  ...SuggestionStore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

type IProps = {
} & PropsFromRedux;
function MenuBar(props: IProps){
  const [isHomePage, setIsHomePage] = useState<boolean>();
  const location = useLocation();
  const permHelper = new PermissionHelper(props.authentication.permissions);
  const pathURL = location.pathname;

  useEffect(() => {
    setIsHomePage(pathURL === '/');
  }, [location]);

  const displayTrainingSuggestions = () => {
    props.requestTrainingSuggestions();
  };

  const canViewTrainingIcon = () => {
    return isHomePage && !permHelper.HasPermission(Permissions.CreateManualSuggestion);
  };

  const isAdminOnMimicMode = () => {
    return permHelper.HasAccessToMultipleStores() && props.authentication.mimicScoringEntity;
  };

  const isTrainingAreaPage = () => {
    return pathURL.startsWith('/training/');
  };

  
  
  return (
    <div className="menu-bar">
      <div className="inner-container">
        <div className="header-left">
          <Link to='/'><button className='logo'><img src='/assets/costa-logo.png'/></button></Link>
          {
            permHelper.IsAdmin() && !isAdminOnMimicMode() &&
              <Link to="/training-management">Training Area</Link>
          }
          {
            (permHelper.IsStoreUser() ||
            isAdminOnMimicMode()) &&
            <>
              <Link to="/history">History</Link>
              <Link to="/scorecard">Scorecard</Link>
              <Link to="/morning-brew">Morning Brew</Link>
              <Link to="/daily-forecast">Daily Forecast</Link>
              <Link to="/training">Training</Link>
            </>
          }
        </div>
        <Notification/>
        <div>
          { 
            canViewTrainingIcon() &&
            <img className='training-icon' 
              src='/assets/info-icon.png'
              onClick={displayTrainingSuggestions}/>            
          }
        </div>
      </div>
      {
        !isHomePage && !permHelper.HasPermission(Permissions.ViewAdminDashboard) && (
          (isTrainingAreaPage()
            ? (
              <Link className='back-home-btn'
                to='/training'>
          Training area homepage
              </Link>
            ) : (
              <Link className='back-home-btn'
                to='/'>
          Back to homepage
              </Link>
            )
          )
        )
      }
    </div>
  );
}

export default connect(
  mapState,
  mapDispatch,
)(MenuBar);
