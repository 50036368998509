import * as React from 'react';
import { MetricFormatTypes } from '../../../store/MorningBrew/MorningBrew';
import { WithRouter } from '../../Shared/Routing/WithRouter';
import CardArrowIcon from './CardArrowIcon';

type IProps = {
  metric: string,
  value: number,
  increaseGood?: boolean,
  change?: number
  format: string
};


class MorningBrewCard extends React.PureComponent<IProps, {}> {

  constructor(props: IProps) {
    super(props);
  }

  public getIconColor() {
    const { change, increaseGood } = this.props;
    if(change) {
      if((change > 0 && increaseGood) || (!increaseGood && change < 0)) {
        return 'green';
      }
    }

    return 'red';
  }
  
  public getChangeColour() {
    const { change, increaseGood } = this.props;

    if(change) {
      if((increaseGood && change > 0) || (!increaseGood && change < 0)) {
        return 'good';
      } else {
        return 'bad';
      }
    }
  }

  public getValueFormat(value: number, format: string) {
    switch(format) {
    case MetricFormatTypes.Currency:
      return value && '£' + parseFloat(value.toString()).toFixed(2);
    default: 
      return value;
    }
  }

  public render() {
    const { metric, value, change, format } = this.props;

    return(
      <div className="card-item">
        <div className="header">
          <div className="title">{metric}</div>
          <div className="percentage">
            {change &&  <CardArrowIcon iconColor={this.getIconColor()}
              iconDirectionClassName={ change > 0 ? 'up-arrow' : ''}/> }
            <span className={ this.getChangeColour()}>{change && `${parseFloat(change.toString()).toFixed(1)}%`}</span>
          </div>
        </div>
        <div className={`content ${ format === MetricFormatTypes.String ? 'text' : '' }`}>{this.getValueFormat(value, format)}</div>
      </div>
    );}
}

export default (WithRouter(MorningBrewCard));
