import { SignInButton } from '../SignInButton';

const LoginPage = () => {
  return(
    <div className="app">      
      <div className="body-wrapper">
        <div className="login-container">
          <h2>Digital Store Assistant</h2>
          <h3>Sign In</h3>
          <div className='form'>
            <SignInButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default (LoginPage);
