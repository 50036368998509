import React from 'react';
import { Link } from 'react-router-dom';

interface CardProps {
  title: string;
  imageSrc: string;
  link?: string;
  className: string;
}

const TrainingCard: React.FC<CardProps> = ({ title, imageSrc, link, className }) => {
  if (link) {
    return (
      <div className='training-card-link-margin'>
        <Link to={link}>
          <img src={imageSrc}
            alt={title}
            className={className} />
          <div className="training-card-tile">{title}</div>
        </Link>
      </div>
    );
  }

  return (
    <div className='training-card-margin'>
      <img src={imageSrc}
        alt={title}
        className={className} />
      <div className="training-card-tile">{title}</div>
    </div>
  );
};

export default TrainingCard;
