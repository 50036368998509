import { AssetKeys } from '../../AssetKeys';
import { Suggestion, SuggestionCategory, SuggestionType } from '../../store/Suggestion/Suggestion';
import { SwipeableContent } from '../Shared/Swipeable/Swipeable';

const MORNING_BREW_TITLE = 'View your Morning Brew';
export const FULL_SWIPE_THRESHOLD = 50; // percentage
export const SWIPE_ACTION_THRESHOLD = 0.1; // percentage
export const SUB_SUGGESTION_SWIPE_ACTION_THRESHOLD = 0.5; // percentage
export const ANIMATION_DURATION = 1000; // milliseconds
export const ANIMATION_DURATION_LONGER = 1500; // milliseconds
export const QUEUE_INTERVAL = 30000; //milliseconds

export const LOADING_SUGGESTION: Suggestion = {
  id: 0,
  title: '',
  description: '',
  siteID: '',
  timeSlot: '',
  category: '',
  priority: 0,
  obscurity: 0,
  type: '',
}; 

export const DEFAULT_SUGGESTION: Suggestion = {
  id: 0,
  title: 'That\'s all for now.',
  description: 'You\'ve dismissed all of the suggestions we have for now. Keep your eyes peeled, more suggestions will be generated soon.',
  siteID: '',
  timeSlot: '',
  category: '',
  priority: 0,
  obscurity: 0,
  type: '',
}; 

const celebrationSuggestionDescriptions = [        
  'Great job!', 
  'Keep it up!', 
  'You\'re on a roll!', 
  'Amazing!',
  'Blimey, you\'re smashing it!',
  'To infinity, and beyond!',
  'Raise the roof!',
  'Enjoy the view from the top, superstars!',
  'Jolly good show!'];

const getRandomDescription = (descriptions: string[]) => {
  const randomIndex = Math.floor(Math.random() * descriptions.length);
  return descriptions[randomIndex];
};

export const DefaultSwipeContent: SwipeableContent = {
  leftContent: {
    imgSrc: AssetKeys.thumbs_down_icon,
    text: 'Bad Suggestion',
  },
  rightContent: {
    imgSrc: AssetKeys.thumbs_up_icon,
    text: 'Helpful Suggestion',
  },
};

export const getCelebrationSuggestion = (randomContentAlreadyShown: boolean) => {
  if(!randomContentAlreadyShown) {
    CelebrationSwipeContent.leftContent.text = getRandomDescription(celebrationSuggestionDescriptions);
    CelebrationSwipeContent.rightContent.text = getRandomDescription(celebrationSuggestionDescriptions);
  }
  return CelebrationSwipeContent;
};

const CelebrationSwipeContent: SwipeableContent = {
  leftContent: {
    imgSrc: AssetKeys.fireworks_icon,
    text: getRandomDescription(celebrationSuggestionDescriptions),
  },
  rightContent: {
    imgSrc: AssetKeys.fireworks_icon,
    text: getRandomDescription(celebrationSuggestionDescriptions),
  },
};

export const ReminderSwipeContent: SwipeableContent = {
  leftContent: {
    imgSrc: AssetKeys.incomplete_action_icon,
    text: 'Task Not Completed',
  },
  rightContent: {
    imgSrc: AssetKeys.complete_action_icon,
    text: 'Task Completed',
  },
};

export const ActionSuggestionSwipeContent: SwipeableContent = {
  leftContent: {
    imgSrc: AssetKeys.incomplete_action_icon,
    text: 'Suggestion Not Completed',
  },
  rightContent: {
    imgSrc: AssetKeys.complete_action_icon,
    text: 'Suggestion Completed',
  },
};

export const MorningBrewSwipeContent: SwipeableContent = {
  leftContent: {
    imgSrc: AssetKeys.fireworks_icon,
    text: 'We\'re taking you to your Morning Brew! Hooray!',
  },
  rightContent: {
    imgSrc: AssetKeys.fireworks_icon,
    text: 'You\'re being taken to your Morning Brew! Hooray!',
  },
};

export const EmptySwipeContent: SwipeableContent = {
  leftContent: {},
  rightContent: {},
};

export const SortByPriorityAndObscurity = (a: Suggestion, b: Suggestion) => (b.priority + b.obscurity) - (a.priority + a.obscurity);

export const IsMorningBrewSuggestion = (title: string) => {
  return title === MORNING_BREW_TITLE;
};

export const IsCelebrationSuggestion = (category: string) => {
  return category === SuggestionCategory.Celebration;
};

export const IsReminderSuggestion = (category: string) => {
  return category === SuggestionCategory.Reminder;
};

export const IsSubSuggestion = (subSuggestionId: number | undefined) => {
  return subSuggestionId && subSuggestionId > 0;
};

export const IsQueueSuggestion = (type: string) => {
  return type === SuggestionType.Queue;
};