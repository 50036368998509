/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppThunkAction } from '../index';
import { Action, Reducer, AnyAction } from 'redux';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ReleaseGuideState {
    isLoading: boolean;
    isErrored: boolean;
    errorMessage: string;
    isUpdated: boolean;
    content: Release[],
}

export interface Release {
  id: number;
  releaseNumber: string;
  title: string;
  description: string;
  isSummary: boolean;
  releaseDateTime: string;
  contentImages: string[];
}


// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something
// that is going to happen.

export interface RequestReleaseGuide extends AnyAction {
    type: 'REQUEST_RELEASE_GUIDE';
}

export interface ReceiveReleaseGuide extends AnyAction {
    type: 'RECEIVE_RELEASE_GUIDE';
    payload: any;
}

export interface FailedReleaseGuide extends AnyAction {
    type: 'FAILED_RELEASE_GUIDE';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type'
// properties contain one of the
// declared type strings (and not any other arbitrary string).
export type AreaAction = RequestReleaseGuide | ReceiveReleaseGuide | FailedReleaseGuide;
                         
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger
// a state transition.
// They don't directly mutate state, but they can have external side-effects
// (such as loading data).

export const actionCreators = {
  requestReleaseGuide: (localDate: string, releaseNumber: string):
    AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.releaseGuide && appState.releaseGuide.isLoading === false) {
      dispatch({ 
        type: 'REQUEST_RELEASE_GUIDE',
        http: {
          verb: 'GET',
          endpoint: `/api/content/releaseguide?localDate=${localDate}&releaseNumber=${releaseNumber}`,
          successAction: 'RECEIVE_RELEASE_GUIDE',
          failureAction: 'FAILED_RELEASE_GUIDE',
        }, 
      });
    }
  },

  requestReleaseGuideCatalog: (localDate: string):
    AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.releaseGuide && appState.releaseGuide.isLoading === false) {
      dispatch({ 
        type: 'REQUEST_RELEASE_GUIDE',
        http: {
          verb: 'GET',
          endpoint: `/api/content/releaseguidecatalog?localDate=${localDate}`,
          successAction: 'RECEIVE_RELEASE_GUIDE',
          failureAction: 'FAILED_RELEASE_GUIDE',
        }, 
      });
    }
  },
};

// REDUCER - For a given state and action, returns the new state. To support time travel,
// this must not mutate the old state.

const unloadedState: ReleaseGuideState = {
  errorMessage: '',
  isErrored: false,
  isLoading: false,
  isUpdated: false,
  content:[],
};

export const reducer: Reducer<ReleaseGuideState> = (state: ReleaseGuideState | undefined,
  incomingAction: Action):ReleaseGuideState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as AreaAction;
  switch (action.type) {
  case 'REQUEST_RELEASE_GUIDE':
    return {
      ...unloadedState,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
    };
  case 'RECEIVE_RELEASE_GUIDE':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      content: action.payload ,
    };
  case 'FAILED_RELEASE_GUIDE':
    return {
      ...unloadedState,
      errorMessage: 'An error occurred while receiving the data.',
      isErrored: true,
      isLoading: false,
    };
  default:
    return state;
  }
};

