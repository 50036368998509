import React, { useState, useEffect } from 'react';

interface ProgressBarProps {
  min: number;
  max: number;
}

const TimedProgressBar: React.FC<ProgressBarProps> = ({ min, max }) => {
  const [progress, setProgress] = useState(min);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + 1000;
        return newProgress > max ? min : newProgress;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [max, min]);

  const percentage = ((progress - min) / (max - min)) * 100;

  return (
    <div className='timed-progress-bar'>
      <div
        className='bar'
        style={{
          width: `${percentage}%`,
        }}
      />
    </div>
  );
};

export default TimedProgressBar;
