import * as React from 'react';
import { SeasonalContent as SeasonalContentModel } from '../../../store/DailyForecast/DailyForecast';
import { ImageViewer } from '../../Shared/ImageViewer/ImageViewer';
import { useState } from 'react';
import { Loading } from '../../Shared/Loading/Loading';

type IProps = {
  content: SeasonalContentModel,
};

const SeasonalContent: React.FC<IProps> = ({ content }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [displayImgViewer, setDisplayImgViewer] = useState(false);

  return(
    <>
      {
        content.featuredImage && !imageLoaded && <Loading/>
      }

      {
        content.featuredImage && 
              <div key={content.title} 
                className={`seasonal-content-wrapper ${imageLoaded && 'show'}`}
                onClick={() => setDisplayImgViewer(!displayImgViewer)}>
                <img onLoad={() => setImageLoaded(true)}
                  src={content.featuredImage}/>
                <div className='seasonal-content'>
                  <div className='seasonal-content-title'>{content.title}</div>
                  <div className='seasonal-content-description'>
                    {content.description}
                  </div>
                </div>
              </div>
      }

      {
        displayImgViewer  &&
            <ImageViewer handleToggleImgView={() => setDisplayImgViewer(!displayImgViewer)}
              content={content.contentImages} />
      }
    </>
  );
};

export default SeasonalContent;
