import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ApplicationState } from '../../store';
import './ReleaseGuide.scss';
import * as ReleaseStore from '../../store/Release/Release';
import { useNavigate, useParams } from 'react-router-dom';
import DateUtils from '../../helpers/DateUtils';
import { Loading } from '../Shared/Loading/Loading';
import dateFormat from 'dateformat';

const mapState = (state: ApplicationState) => ({
  releaseGuideState: state.releaseGuide,
});

const mapDispatch = {
  ...ReleaseStore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IProps = PropsFromRedux;

const ReleaseGuideCatalog: React.FC<IProps> = (props) => {
  const { requestReleaseGuideCatalog, releaseGuideState } = props;
  const [releaseSummaryList, setReleaseSummaryList] = useState<ReleaseStore.Release[]>([]);
  const [imageLoaded, setImageLoaded] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    requestReleaseGuideCatalog(DateUtils.getLocalDateString());
  }, [requestReleaseGuideCatalog]);

  useEffect(() => {
    if (releaseGuideState && releaseGuideState.content) {
      setReleaseSummaryList(releaseGuideState.content);
    }
  }, [releaseGuideState]);

  function getFormatTime(dateTimeString: string): string {
    const dateTime = new Date(dateTimeString);
    return dateFormat(dateTime, 'HH:MM');
  }

  function getFormatDate(dateTimeString: string): string {
    const dateTime = new Date(dateTimeString);
    return dateFormat(dateTime, 'mmmm dd, yyyy');
  }

  function openReleaseGuidePage(releaseNumber:string) {
    navigate('/release-guide/' + releaseNumber);
  }
 
  return (
    <div className="app">
      <div className="body-wrapper">
        <h1 className='release-catalog-title'> Release Guides </h1>
        {
          releaseGuideState && releaseGuideState.isLoading && imageLoaded
          && <Loading/>
        }
        {releaseSummaryList.map((release, index) => (
          <div className="release-summary"
            key={index}
            onClick={()=> openReleaseGuidePage(release.releaseNumber)}>
            <div className="release-img-container">
              <img className="release-summary-img"
                src={release.contentImages[0]} 
                onLoad={()=>setImageLoaded(true)} />
            </div>
            <div className="release-summary-text">
              {release.releaseNumber && <div className="release-summary-title">{release.title}</div>}
              {release.description && <p className="release-summary-desc">{release.description}</p>}
              <p className="release-summary-datetime">{getFormatTime(release.releaseDateTime)} <span className='release-summary-date'> {getFormatDate(release.releaseDateTime)}  </span></p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );


};

export default connector(ReleaseGuideCatalog);
