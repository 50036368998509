import React from 'react';
import { animated, useSpring } from 'react-spring';
import { ANIMATION_DURATION } from '../../../Suggestion/SuggestionUtils';

interface ShakeAnimationProps<T> {
  children: React.ReactElement<T>;
  isAnimating: boolean;
}

const ShakeAnimation = <T extends {}>({ children, isAnimating }: ShakeAnimationProps<T>) => {

  const styles =  useSpring({
    opacity: isAnimating ? 1 : 0,
    transform: isAnimating ? 'scale(1)' : 'scale(0)',
    config: {
      mass: 2,
      tension: 600,
      friction: 20,
    },
    immediate: !isAnimating,
    reset: isAnimating,
    from: {
      opacity: 0,
      transform: 'scale(0.6)',
    },
    to: {
      opacity: 1,
      transform: 'scale(1)',
    },
    delay : isAnimating ? 10 : 0,
    duration: isAnimating ? ANIMATION_DURATION : 0,
  });
  
  return <animated.div style={styles}>{React.Children.only(children)}</animated.div>;
};

export default ShakeAnimation;
