/* eslint-disable no-debugger */
import * as React from 'react';
import { ApplicationState } from '../../../store';
import { connect, ConnectedProps } from 'react-redux';
import { WithRouter } from '../../Shared/Routing/WithRouter';
import 'react-datepicker/dist/react-datepicker.css';
import * as AuthenticationStore from '../../../store/Authentication/Authentication';
import * as DailyForecastStore from '../../../store/DailyForecast/DailyForecast';
import * as SuggestionStore from '../../../store/Suggestion/Suggestion';
import * as Remindestore from '../../../store/Reminder/Reminder';
import { Loading } from '../../Shared/Loading/Loading';
import Chart from 'react-apexcharts';
import { breakTimeAnnonations, salesForecastOptions, salesForecastSeries, StatisticsData } from './DailyForecastChartOptions';
import dateFormat from 'dateformat';
import ListMetric from '../Common/ListMetric';
import { ListMetricItem } from '../../../store/DailyForecast/DailyForecast';
import { ApexOptions } from 'apexcharts';
import WeatherWidget from '../../Shared/WeatherWidget/WeatherWidget';
import DailySuggestionCard from '../Common/DailySuggestionCard';
import { AssetKeys } from '../../../AssetKeys';
import SeasonalContent from '../Common/SeasonalContent';
import { ContentBanner } from '../Common/ContentBanner';
import DateUtils from '../../../helpers/DateUtils';
import SeasonalRecipeContentList from '../Common/SeasonalRecipeContentList';


const mapState = (state: ApplicationState) => ({
  suggestionState: state.suggestion,
  dailyForecast: state.dailyForecast,
  authentication: state.authentication,
  reminderState: state.reminder,
});

const mapDispatch = {
  ...AuthenticationStore.actionCreators,
  ...DailyForecastStore.actionCreators,
  ...SuggestionStore.actionCreators,
  ...Remindestore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

type IProps = PropsFromRedux;

type IState = {
  listMetrics: DailyForecastStore.ListMetric[],
  salesForecastSeries: StatisticsData[],
  salesForecastOptions: ApexOptions,
}

// breaks are not allowed to be suggested within this amount of hours of store opening or closing
const RunningOutLabel = 'Running Out ';
const PlanYourBreakTitle = 'Plan your breaks!';
const RecipeSeasonalContentType = 'recipe';
class DailyForecastPage extends React.PureComponent<IProps, IState> {

  constructor(props: IProps) {
    super(props);

    this.state = {
      listMetrics: [],
      salesForecastOptions,
      salesForecastSeries,
    };
  }

  public componentDidMount() {
    const { authentication, requestDailyForecast, requestSeasonalContent, requestReminders } = this.props;
    requestDailyForecast(authentication.selectedScoringEntity, new Date().toISOString());
    requestSeasonalContent(DateUtils.getLocalDateString());
    requestReminders(DateUtils.getLocalDateString());
  }

  private getCurrentHour = () => {
    const now = new Date();

    now.setMinutes(0);
    now.setMilliseconds(0);

    return dateFormat(now, 'HH:00');
  };

  private incrementHours = (timeStr: String, hours:number) => {
    const time = new Date();
    time.setHours(parseInt( timeStr.split(':')[0]));
    time.setMinutes(parseInt(timeStr.split(':')[1]));
    time.setHours(time.getHours() + hours);
    return dateFormat(time, 'HH:MM');
  };

  private planYourBreaksDescription = () => {
    const { dailyForecast } = this.props; 
    const breaks = dailyForecast.suggestedBreakTimes.map((time, i) => 
    {
      const start = time.toString();
      const end = this.incrementHours(start, 1);
      return `${start} - ${end}`; 
    }); 
    return `We anticipate the best time for breaks today are between ${breaks.join(' and ')}.
           Taking breaks here has the lowest impact on service during estimated busy periods.`; 
  };

  componentDidUpdate(prevProps: IProps): void {
    const { dailyForecast } = this.props;
    const listMetrics = [];

    if (prevProps.dailyForecast.listMetrics.length !== dailyForecast.listMetrics.length) {  
      for (let i = 0; i < dailyForecast.listMetrics.length; i++) {
        listMetrics.push(dailyForecast.listMetrics[i]);
      }

      this.setState({ listMetrics });
    }

    if (prevProps.dailyForecast.trends.length !== dailyForecast.trends.length) {
      // transactions per hour
      salesForecastSeries[0].data = [];
      // hour with the highest transactions
      salesForecastSeries[1].data = [];
      // current hour
      salesForecastSeries[2].data = [];
      // suggested breaks
      salesForecastSeries[3].data = [];

      if(dailyForecast.trends && dailyForecast.trends.length > 0) {
        const hourWithMaxTransactions = Math.max(...dailyForecast.trends[0].values.map(x => x.value));
        const currentHour = this.getCurrentHour();
        const breakTimes= dailyForecast.suggestedBreakTimes;
    
        dailyForecast.trends[0].values.forEach((val) => {
          if (val.value === hourWithMaxTransactions) {
            salesForecastSeries[0].data.push(0);
            salesForecastSeries[1].data.push(val.value);
            salesForecastSeries[2].data.push(0);
            salesForecastSeries[3].data.push(0);
          } else if (breakTimes.includes(val.label)) {
            salesForecastSeries[0].data.push(0);
            salesForecastSeries[1].data.push(0);
            salesForecastSeries[2].data.push(0);
            salesForecastSeries[3].data.push(val.value);
          } else if (val.label === currentHour) {
            salesForecastSeries[0].data.push(0);
            salesForecastSeries[1].data.push(0);
            salesForecastSeries[2].data.push(val.value);
            salesForecastSeries[3].data.push(0);
          } else {
            salesForecastSeries[0].data.push(val.value);
            salesForecastSeries[1].data.push(0);
            salesForecastSeries[2].data.push(0);
            salesForecastSeries[3].data.push(0);
          }
        });

        if (salesForecastOptions.xaxis) {
          salesForecastOptions.xaxis.categories = dailyForecast.trends[0].values.map(x => x.label);
        }

        //break suggestions
        breakTimeAnnonations.points = [
          {
            x: breakTimes[0].toString(),
            y: dailyForecast.trends[0].values.find(x=>x.label===breakTimes[0])?.value,
            marker: {
              size: 0,
            },
            image: {
              path: AssetKeys.suggested_break_icon,
              height: 70,
              width: 70,
              offsetY: 50,
            },
          },
          {
            x: breakTimes[1].toString(),
            y: dailyForecast.trends[0].values.find(x=>x.label===breakTimes[1])?.value,
            marker: {
              size: 0,
            },
            image: {
              path: AssetKeys.suggested_break_icon,
              height: 70,
              width: 70,
              offsetY: 50,
            },
          },
        ];

        this.setState({ listMetrics, salesForecastOptions, salesForecastSeries });
      }
    }
  }

  public getRunOutDay (value: number) {
    let runningOutLabel = RunningOutLabel;
    if(value === 1) runningOutLabel += 'Today';
    else if (value === 2) runningOutLabel += 'Tomorrow';
    else runningOutLabel += `On ${value} days`;

    return runningOutLabel;
  }

  public constructStockStatusListMetric(listMetric: DailyForecastStore.ListMetric) {
    return listMetric.values.map((x: ListMetricItem) => {
      return (
        <div key={x.itemNumber}>
          <div
            className='list-metric-item'>
            <div className='item-number'>{x.itemNumber}</div>
            <div className='item-description'>{x.itemDescription}</div>
            <div className='item-running-out'>{this.getRunOutDay(x.numOfDaysBeforeRunOut)}</div>
           
          </div>
          {
            listMetric.values.indexOf(x) < listMetric.values.length - 1 && <div className='break-line'></div>
          }
          
        </div>
      );
    });
  }

  public render() {
    const { dailyForecast, reminderState } = this.props;
    
    const recipeContent = dailyForecast.seasonalContent?.filter(s => s.type === RecipeSeasonalContentType);
    const seasonalContent= dailyForecast.seasonalContent?.filter(s => s.type !== RecipeSeasonalContentType);
   
    return (
      <div className="app">
        <div className="body-wrapper">
          {
            dailyForecast && dailyForecast.isLoading && <Loading />
          }

          {/* Daily Suggestions */}

          {
            dailyForecast && !dailyForecast.isLoading &&
            <div className="daily-forecast-container">
              <h1 className="daily-forecast-header title">Daily Forecast</h1>

              {
                reminderState.isLoading 
                  ?
                  <Loading/>
                  :
                  reminderState.reminders.map(reminder => {
                    return (
                      <ContentBanner key={reminder.description}
                        body={reminder.description}
                        type={reminder.type} />
                    );
                  })
              }

              

              {
                dailyForecast.dailySuggestions && dailyForecast.dailySuggestions.sort((a, b) => (a.priority > b.priority) ? -1 : 1).map((card, index) => {
                  return <DailySuggestionCard id={index+1}
                    title={card.title}
                    content={card.description}
                    key={index + 1}/>;
                })
              }

              {/* Daily Forecast Chart */}

              {
                this.state.salesForecastOptions.xaxis && this.state.salesForecastOptions.xaxis.categories.length > 0 && this.state.salesForecastSeries[0].data.length > 0 &&
                <>
                  <div className="chart">
                    <Chart
                      options={this.state.salesForecastOptions}
                      series={this.state.salesForecastSeries}
                      type="bar"
                    />
                  </div>
                  <DailySuggestionCard 
                    title={PlanYourBreakTitle}
                    content={this.planYourBreaksDescription()}
                    imagePath={AssetKeys.suggested_break_icon} />;
                </>
              }

              {/* Running out soon table */}

              {/* {
                dailyForecast.listMetrics.length > 0 &&
                <div className="list-metrics-wrapper">
                  {
                    dailyForecast.listMetrics.map(listMetric => {
                      return (
                        <ListMetric key={listMetric.metric}
                          title={listMetric.metric}
                          rows={this.constructStockStatusListMetric(listMetric)} />
                      );
                    })
                  }
                </div>
              } */}

              {/* Seasonal Content */}
              {
                !dailyForecast.seasonalContent || dailyForecast.seasonalContentLoading ? (
                  <Loading />
                ) : 
                  (
                    <>
                      {recipeContent && 
                      <SeasonalRecipeContentList content={recipeContent} />}
                      {seasonalContent &&
                      seasonalContent
                        .sort((a, b) => (a.validFrom < b.validFrom ? 1 : -1))
                        .map((x) => (
                          <SeasonalContent key={x.title}
                            content={x} />
                        ))}
                    </>
                  )
              }
              <WeatherWidget />

              <div className="footer">
                <a href='/'>Start Your Day</a>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default connector(WithRouter(DailyForecastPage));
