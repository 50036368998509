type TeaxAreaProps = {
    placeholderText: string,
    required?: boolean,
    onChange: Function,
    value?: string,
    valid?: boolean,
    name:string,
    label:string,
    maxLength?: number

}

function TextArea (props: TeaxAreaProps) {
  const {
    label, name, valid, value, maxLength, onChange, required, placeholderText,
  } = props;

  return (
    <label
      htmlFor={name}
      className="textarea"
    >
      {label}
      <textarea
        name={name}
        placeholder={placeholderText}
        onChange={(e) => onChange(e.target.value)}
        maxLength = {maxLength}
        value={value}
        required={required}
        className={valid ? 'invalid' : ''}
      />
    </label>
  );
}

export default TextArea;
