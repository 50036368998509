/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppThunkAction } from '../index';
import { Action, Reducer, AnyAction } from 'redux';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface WeatherState {
    isLoading: boolean;
    isErrored: boolean;
    errorMessage: string;
    data: WeatherData,
}

export enum WeatherCodes {
    Clear = 0,
    MainlyClear = 1,
    PartlyCloudy = 2,
    Cloudy = 3,
    Fog = 45,
    DepositingRimeFog = 48,
    LightDrizzle = 51,
    ModerateDrizzle = 53,
    DenseIntensityDrizzle = 55,
    LightFreezingDrizzle = 56,
    DenseIntensityFreezingDrizzle = 57,
    SlightRain = 61,
    ModerateRain = 63,
    HeavyIntensityRain = 65,
    LightFreezingRain = 66,
    HeavyIntensityFreezingRain = 67,
    SlightSnowFall = 71,
    ModerateSnowFall = 73,
    HeavyIntensitySnowFall = 75,
    SnowGrains = 77,
    SlightRainShowers = 80,
    ModerateRainShowers = 81,
    ViolentRainShowers = 82,
    SlightSnowShowers = 85,
    HeavySnowShowers = 86,
    SlightThunderstorm = 95,
    SlightThunderstormWithHail = 96,
    ThunderstormWithHeavyHail = 99,
}

export const WeatherDescriptions: Record<number, string> = {
  0: 'Clear',
  1: 'Mostly Clear',
  2: 'Partly Cloudy',
  3: 'Cloudy',
  45: 'Fog',
  46: 'Rime',
  51: 'Light Drizzle',
  53: 'Drizzle',
  55: 'Drizzle',
  56: 'Freezing Drizzle',
  57: 'Freezing Drizzle',
  61: 'Light Rain',
  63: 'Rain',
  65: 'Heavy Rain',
  66: 'Light Freezing Rain',
  67: 'Freezing Rain',
  71: 'Light Snow Fall',
  73: 'Snow Fall',
  75: 'Snow Fall',
  77: 'Snow Grains',
  80: 'Rain Showers',
  81: 'Rain Showers',
  82: 'Rain Showers',
  85: 'Light Snow Showers',
  86: 'Snow Showers',
  95: 'Thunderstorm',
  96: 'Thunderstom & Hail',
  99: 'Hail',
};


export interface WeatherData {
    latitude: number,
    longitude: number,
    generationtime_ms: number,
    utc_Offset_Seconds: number,
    timezone: string,
    timezone_Abbreviation: string,
    elevation: number,
    current_Weather: {
        temperature: number,
        windSpeed: number,
        windDirection: number,
        weathercode: number,
        time: Date
    },
    hourly_Units: {
        time: string,
        temperature_2m: string,
        rain: string,
        cloudCover: string
    },
    hourly: {
      time: string[],
      temperature_2m: number[],
      rain: number[],
      cloudCover: number[],
      precipitation: number[]
    }
}

// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something
// that is going to happen.

export interface RequestWeatherDataAction extends AnyAction {
    type: 'REQUEST_WEATHER_DATA';
}

export interface ReceiveWeatherDataAction extends AnyAction {
    type: 'RECEIVE_WEATHER_DATA';
    payload: any;
}

export interface FailedWeatherDataAction extends AnyAction {
    type: 'FAILED_WEATHER_DATA';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type'
// properties contain one of the
// declared type strings (and not any other arbitrary string).
export type AreaAction = RequestWeatherDataAction | ReceiveWeatherDataAction | FailedWeatherDataAction;
                         
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger
// a state transition.
// They don't directly mutate state, but they can have external side-effects
// (such as loading data).

export const actionCreators = {
  requestWeatherData: (latitude: number, longitude: number, startDate: string, endDate: string):
    AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.suggestion && appState.suggestion.isLoading === false) {
      dispatch({ 
        type: 'REQUEST_WEATHER_DATA',
        http: {
          verb: 'GET',
          endpoint: `/api/weather/weather?latitude=${latitude}&longitude=${longitude}&startDate=${startDate}&endDate=${endDate}`,
          successAction: 'RECEIVE_WEATHER_DATA',
          failureAction: 'FAILED_WEATHER_DATA',
        }, 
      });
    }
  },
};

// REDUCER - For a given state and action, returns the new state. To support time travel,
// this must not mutate the old state.

const unloadedState: WeatherState = {
  errorMessage: '',
  isErrored: false,
  isLoading: false,

  data: {
    latitude: 0,
    longitude: 0,
    generationtime_ms: 0,
    utc_Offset_Seconds: 0,
    timezone: 'GMT',
    timezone_Abbreviation: 'GMT',
    elevation: 0,
    current_Weather: {
      temperature: 0,
      windSpeed: 0,
      windDirection: 0,
      weathercode: 0,
      time: new Date(),
    },
    hourly_Units: {
      time: 'iso8601',
      temperature_2m: '°C',
      rain: 'mm',
      cloudCover: '%',
    },
    hourly: {
      time: [],
      temperature_2m: [],
      rain: [],
      cloudCover: [],
      precipitation: [],
    },
  },
};

export const reducer: Reducer<WeatherState> = (state: WeatherState | undefined,
  incomingAction: Action) : WeatherState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as AreaAction;
  switch (action.type) {
  case 'REQUEST_WEATHER_DATA':
    return {
      ...unloadedState,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
    };
  case 'RECEIVE_WEATHER_DATA':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      data: action.payload,
    };
  case 'FAILED_WEATHER_DATA':
    return {
      ...unloadedState,
      errorMessage: 'An error occurred while receiving the data.',
      isErrored: true,
      isLoading: false,
    };
  default:
    return state;
  }
};

