import React, { useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import TrainingCard from './TrainingCard';
import { ApplicationState } from '../../store';
import * as TrainingAreaStore from '../../store/TrainingArea/TrainingArea';
import { Loading } from '../Shared/Loading/Loading';

const mapState = (state: ApplicationState) => ({
  trainingArea: state.trainingArea,
});

const mapDispatch = {
  ...TrainingAreaStore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

type IProps = PropsFromRedux;

const TrainingLandingPage = (props: IProps) => {
  const { trainingArea, requestTrainingAreas } = props;

  useEffect(() => {
    requestTrainingAreas();
  }, []);

  if (trainingArea.isLoading) {
    // Show loading component while fetching data
    return <Loading />;
  }

  return (
    <div className="app">
      <div className="body-wrapper">
        <div className="training-container">
          <h1 className='title'>Training</h1>
          <p>If you are getting up to speed or just need a quick reminder, the Training Area has what you need. A full catalogue of drinks recipes, food preparation guides and instructions for your Standard Operating Procedures.</p>
          <div className="get-started-heading">
            <h3>Get Started</h3>
          </div>
          <div className="training-card-container">
            {trainingArea.areas.map((area) => (
              <TrainingCard key={area.id}
                title={area.name}
                imageSrc={area.thumbnail} 
                link={area.id.toString()}
                className='training-card-img'/>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connector(TrainingLandingPage);
