import * as React from 'react';
import { ApplicationState } from '../../../store';
import { connect, ConnectedProps } from 'react-redux';
import { WithRouter } from '../../Shared/Routing/WithRouter';
import 'react-datepicker/dist/react-datepicker.css';
import * as AuthenticationStore from '../../../store/Authentication/Authentication';
import * as MorningBrewStore from '../../../store/MorningBrew/MorningBrew';
import * as SuggestionStore from '../../../store/Suggestion/Suggestion';
import { Loading } from '../../Shared/Loading/Loading';
import Chart from 'react-apexcharts';
import { options, series, StatisticsData } from './MorningBrewChartOptions';
import dateFormat from 'dateformat';
import MorningBrewCard from '../Common/MorningBrewCard';
import { ApexOptions } from 'apexcharts';

const mapState = (state: ApplicationState) => ({
  suggestionState: state.suggestion,
  morningBrew: state.morningBrew,
  authentication: state.authentication,
});

const mapDispatch = {
  ...AuthenticationStore.actionCreators,
  ...MorningBrewStore.actionCreators,
  ...SuggestionStore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

type IProps = PropsFromRedux;

type IState = {
  metrics: MorningBrewStore.Metric[],
  statisticsData: StatisticsData[],
  chartConfig: ApexOptions
}

class MorningBrewPage extends React.PureComponent<IProps, IState> {

  constructor(props: IProps) {
    super(props);

    this.state = {
      metrics: [],
      chartConfig: options,
      statisticsData: series,
    };
  }


  public componentDidMount() {
    const { authentication, updateSuggestion, requestMorningBrew } = this.props;

    requestMorningBrew(authentication.selectedScoringEntity, new Date().toISOString());

    const morningBrew = JSON.parse(localStorage.getItem('selectedMorningBrew') || '{}');

    if (morningBrew && morningBrew.id) {
      const data: SuggestionStore.SuggestionUpdateModel = {
        storeId: authentication.selectedScoringEntity,
        id: morningBrew.id,
        timeslot: morningBrew.timeSlot,
        complete: true,
        actioned: true,
        dismissed: this.getLocalTime(),
      };
      updateSuggestion(data);
      localStorage.setItem('selectedMorningBrew', '{}');
    }
  }

  private getCurrentHour = () => {
    const now = new Date();

    now.setMinutes(0);
    now.setMilliseconds(0);

    return dateFormat(now, 'HH:00');
  };

  componentDidUpdate(prevProps: IProps): void {
    const { morningBrew } = this.props;
    if (prevProps.morningBrew.metrics.length !== morningBrew.metrics.length) {
      const metrics = [];
      for (let i = 2; i < morningBrew.metrics.length; i++) {
        metrics.push(morningBrew.metrics[i]);
      }

      this.setState({ metrics });
    }

    if (prevProps.morningBrew.trends.length !== morningBrew.trends.length) {
      // transactions per hour
      series[0].data = [];
      // hour with the highest transactions
      series[1].data = [];
      // current hour
      series[2].data = [];

      if (options.xaxis) {
        options.xaxis.categories = [];
      }

      if(morningBrew.trends && morningBrew.trends.length > 0) {
        const hourWithMaxTransactions = Math.max(...morningBrew.trends[0].values.map(x => x.value));
        const currentHour = this.getCurrentHour();

        for (let i = 0; i < morningBrew.trends[0].values.length; i++) {
          if (morningBrew.trends[0].values[i].value === hourWithMaxTransactions) {
            series[0].data.push(0);
            series[1].data.push(`${morningBrew.trends[0].values[i].value}-${morningBrew.trends[1].values[i].value}`);
            series[2].data.push(0);
          } else if (morningBrew.trends[0].values[i].label === currentHour) {
            series[0].data.push(0);
            series[1].data.push(0);
            series[2].data.push(`${morningBrew.trends[0].values[i].value}-${morningBrew.trends[1].values[i].value}`);
          } else {
            series[0].data.push(`${morningBrew.trends[0].values[i].value}-${morningBrew.trends[1].values[i].value}`);
            series[1].data.push(0);
            series[2].data.push(0);
          }

          if (options.xaxis) {
            options.xaxis.categories.push(morningBrew.trends[0].values[i].label);
          }
        }

        this.setState({ chartConfig: options, statisticsData: series });
      }
    }
  }

  public getLocalTime() {
    return new Date((new Date().setHours(new Date().getHours() - (new Date().getTimezoneOffset() / 60))));
  }

  public getTargetRevenueSliderProgress(value: number, target: number) {
    const width = (value / target) * 100;
    const percentage = (width / 100) * 30;
    const finalValue = width - percentage;

    return { width: `${finalValue <= 100 ? finalValue : 100}%` };
  }

  public getCurrentRevenueSliderTextPosition(value: number, target: number) {
    const width = (value / target) * 100;
    const percentage = (width / 100) * 30;
    let finalValue = (width - percentage) / 2;

    if (finalValue < 6) {
      finalValue = 6;
    } else if (finalValue > 45) {
      finalValue = 45;
    }

    return { left: `${finalValue > 6 ? finalValue : 6}%` };
  }



  public render() {
    const { morningBrew } = this.props;
    return (
      <div className="app">
        <div className="body-wrapper">
          {
            morningBrew && morningBrew.isLoading && <Loading />
          }
          {
            morningBrew && !morningBrew.isLoading && morningBrew.metrics.length > 0 &&
            <div className="morning-brew-container">
              <>
                <h1 className="morning-brew-header">Here&apos;s your Morning Brew!</h1>
                <div className="percentage-card">
                  <div className="title">Weekly {morningBrew.metrics[0].metric}</div>
                  <div className="content">
                    <div className="percentage-wrapper">
                      <p style={this.getCurrentRevenueSliderTextPosition(morningBrew.metrics[0].value, morningBrew.metrics[1].value)}
                        className="current-expense">£{morningBrew.metrics[0].value}</p>
                      <div style={this.getTargetRevenueSliderProgress(morningBrew.metrics[0].value, morningBrew.metrics[1].value)}
                        className="percentage"></div>
                      <div className='expectedTarget'></div>
                    </div>
                    <p className="max-expense">£{morningBrew.metrics[1].value}</p>
                  </div>
                </div>
                <h1 className="morning-brew-header">Yesterday&apos;s Key Stats</h1>
                <div className="cards">
                  {this.state.metrics.map(m => {
                    return (
                      <MorningBrewCard key={m.metric}
                        metric={m.metric}
                        value={m.value}
                        change={m.change}
                        format={m.format}
                        increaseGood={m.increaseGood}
                      />
                    );
                  })}
                </div>
              </>
              {
                morningBrew && !morningBrew.isLoading && this.state.chartConfig.xaxis && this.state.chartConfig.xaxis.categories.length &&
                this.state.statisticsData[0].data.length &&
                <div className="chart">
                  <Chart
                    options={this.state.chartConfig}
                    series={this.state.statisticsData}
                    type="bar"
                  />
                </div>
              }
              <div className="footer">
                <a href='/daily-forecast'>Daily Forecast</a>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default connector(WithRouter(MorningBrewPage));
