/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppThunkAction } from '../index';
import { Action, Reducer, AnyAction } from 'redux';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface NotificationState {
    isLoading: boolean;
    isErrored: boolean;
    errorMessage: string;
    isUpdated: boolean;
    notifications: Notification[],
    hasNewNotifications: boolean,
}

export interface Notification {
  id: number;
  title: string;
  description: string;
  link: string;
  notificationDate: string;
  isRead: boolean;
}


// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something
// that is going to happen.

export interface RequestNotifications extends AnyAction {
    type: 'REQUEST_NOTIFICATIONS';
}

export interface ReceiveNotifications extends AnyAction {
    type: 'RECEIVE_NOTIFICATIONS';
    payload: any;
}

export interface FailedNotifications extends AnyAction {
    type: 'FAILED_NOTIFICATIONS';
}
export interface RequestHasNewNotifications extends AnyAction {
    type: 'REQUEST_HAS_NEW_NOTIFICATIONS';
}

export interface ReceiveHasNewNotifications extends AnyAction {
    type: 'RECEIVE_HAS_NEW_NOTIFICATIONS';
    payload: any;
}
export interface RequestMarkNotificationAsRead extends AnyAction {
    type: 'REQUEST_MARK_NOTIFICATION_AS_READ';
    payload: any;
}
export interface ReceiveMarkNotificationAsRead extends AnyAction {
    type: 'RECEIVE_MARK_NOTIFICATION_AS_READ';
    payload: any;
}
export interface RequestMarkAllNotificationAsRead extends AnyAction {
    type: 'REQUEST_MARK_ALL_NOTIFICATION_AS_READ';
    payload: any;
}
export interface ReceiveMarkAllNotificationAsRead extends AnyAction {
    type: 'RECEIVE_MARK_ALL_NOTIFICATION_AS_READ';
    payload: any;
}
// Declare a 'discriminated union' type. This guarantees that all references to 'type'
// properties contain one of the
// declared type strings (and not any other arbitrary string).
export type AreaAction = RequestNotifications | ReceiveNotifications | FailedNotifications 
                         | RequestHasNewNotifications | ReceiveHasNewNotifications 
                         | RequestMarkNotificationAsRead | ReceiveMarkNotificationAsRead 
                         | RequestMarkAllNotificationAsRead | ReceiveMarkAllNotificationAsRead;
                         
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger
// a state transition.
// They don't directly mutate state, but they can have external side-effects
// (such as loading data).

export const actionCreators = {
  requestNotifications: ():
    AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.notification && appState.notification.isLoading === false) {
      dispatch({ 
        type: 'REQUEST_NOTIFICATIONS',
        http: {
          verb: 'GET',
          endpoint: '/api/content/usernotifications',
          successAction: 'RECEIVE_NOTIFICATIONS',
          failureAction: 'FAILED_NOTIFICATIONS',
        }, 
      });
    }
  },
  hasNewNotifications: ():
    AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.notification && appState.notification.isLoading === false) {
      dispatch({ 
        type: 'REQUEST_HAS_NOTIFICATIONS',
        http: {
          verb: 'GET',
          endpoint: '/api/content/hasnewnotifications',
          successAction: 'RECEIVE_HAS_NEW_NOTIFICATIONS',
          failureAction: 'FAILED_NOTIFICATIONS',
        }, 
      });
    }
  },
  markNotificationAsRead: (notificationId: number):
    AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.notification && appState.notification.isLoading === false) {
      dispatch({ 
        type: 'REQUEST_MARK_NOTIFICATION_AS_READ',
        http: {
          verb: 'POST',
          endpoint: '/api/content/marknotificationasread',
          body: { notificationID: notificationId },
          successAction: 'RECEIVE_MARK_NOTIFICATION_AS_READ',
          failureAction: 'FAILED_NOTIFICATIONS',
        }, 
      });
    }
  },
  markAllNotificationsAsRead: ():
    AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.notification && appState.notification.isLoading === false) {
      dispatch({ 
        type: 'REQUEST_MARK_ALL_NOTIFICATION_AS_READ',
        http: {
          verb: 'POST',
          endpoint: '/api/content/markallnotificationsasread',
          successAction: 'RECEIVE_MARK_ALL_NOTIFICATION_AS_READ',
          failureAction: 'FAILED_NOTIFICATIONS',
        }, 
      });
    }
  },
};

// REDUCER - For a given state and action, returns the new state. To support time travel,
// this must not mutate the old state.

const unloadedState: NotificationState = {
  errorMessage: '',
  isErrored: false,
  isLoading: false,
  isUpdated: false,
  notifications:[],
  hasNewNotifications: false,
};

export const reducer: Reducer<NotificationState> = (state: NotificationState | undefined,
  incomingAction: Action):NotificationState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as AreaAction;
  switch (action.type) {
  case 'REQUEST_NOTIFICATIONS':
    return {
      ...unloadedState,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
    };
  case 'RECEIVE_NOTIFICATIONS':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      notifications: action.payload ,
    };
  case 'REQUEST_HAS_NEW_NOTIFICATIONS':
    return {
      ...unloadedState,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
    };
  case 'RECEIVE_HAS_NEW_NOTIFICATIONS':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      hasNewNotifications: action.payload,
    };
  case 'REQUEST_MARK_NOTIFICATION_AS_READ':
    return {
      ...unloadedState,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
      notifications: state.notifications,
      hasNewNotifications: state.hasNewNotifications,
    };
  case 'RECEIVE_MARK_NOTIFICATION_AS_READ': {
    const updatedNotifications = state.notifications.map(notification =>
      notification.id === action.payload
        ? { ...notification, isRead: true }
        : notification,
    ) as Notification[];
    const hasUnreadNotifications = updatedNotifications.some(notification => !notification.isRead);
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      notifications: updatedNotifications,
      hasNewNotifications: hasUnreadNotifications,
    };
  }

  case 'REQUEST_MARK_ALL_NOTIFICATION_AS_READ':
    return {
      ...unloadedState,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
      notifications: state.notifications,
      hasNewNotifications: state.hasNewNotifications,
    };
  case 'RECEIVE_MARK_ALL_NOTIFICATION_AS_READ': {
    const updatedNotifications = state.notifications.map(notification => ({
      ...notification,
      isRead: true,
    }));
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      notifications: updatedNotifications,
      hasNewNotifications: false,
    };
  }
  
  case 'FAILED_NOTIFICATIONS':
    return {
      ...unloadedState,
      errorMessage: 'An error occurred while receiving the data.',
      isErrored: true,
      isLoading: false,
    };
  default:
    return state;
  }
};

