function Scorecard () {
  return(
    <div className="app">      
      <div className="body-wrapper">
        <h1 className="title">{'We are still gathering all the data we need to create your first scorecard. Check back again soon.'}</h1>
        {/* <CardScorecard /> */}
      </div>
    </div>
  );
}

export default (Scorecard);
