import * as React from 'react';
import { ApplicationState } from '../../../store';
import { connect, ConnectedProps } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import * as AuthenticationStore from '../../../store/Authentication/Authentication';
import * as TrainingAreaStore from '../../../store/TrainingArea/TrainingArea';
import { Loading } from '../../Shared/Loading/Loading';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import ConfirmationModal from '../../Shared/ConfirmationModal/ConfirmationModal';
import Pagination from '../../Shared/Pagination/Pagination';
import qs from 'qs';

const mapState = (state: ApplicationState) => ({
  trainingArea: state.trainingArea,
});

const mapDispatch = {
  ...AuthenticationStore.actionCreators,
  ...TrainingAreaStore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

type IProps = PropsFromRedux;

const PAGE_SIZE = 25;

const TrainingManagementPage = (props: IProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [items, setItems] = useState<TrainingAreaStore.TrainingAreaItem[]>([]);
  const [deleteItemId, setDeleteItemId] = useState(0);

  const { trainingArea, requestTrainingAreaItems, deleteTrainingAreaItem } = props;

  const navigate = useNavigate();

  const onSearch = (keyword: string) => {
    keyword = keyword.trim();
    if (keyword.length > 0) {
      const filteredItems = trainingArea.items.filter(x => x.name.toLowerCase().includes(keyword) || x.trainingAreaName.toLowerCase().includes(keyword));
      setItems(filteredItems);
    } else {
      setItems(trainingArea.items);
    }
  };

  useEffect(() => {
    const query = qs.parse(window.location.search.replace('?', ''));
    const queryPageNumKey: number = (query.page && parseInt(query.page.toString())) || 0;

    let page = 1;

    if (queryPageNumKey) {
      page = queryPageNumKey;
    }

    setCurrentPage(page);

    requestTrainingAreaItems(page, PAGE_SIZE);
  }, []);

  useEffect(() => {
    setItems(trainingArea.items);
  }, [trainingArea.items]);

  useEffect(() => {
    requestTrainingAreaItems(currentPage, PAGE_SIZE);
  }, [window.location.search]);

  useEffect(() => {
    if (trainingArea.isDeleted) {
      toast.success('Item successfully deleted');
    }

  }, [trainingArea.isDeleted]);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    navigate(`/training-management?page=${page}`);
  }; 

  const onDeleteItem = () => {
    deleteTrainingAreaItem(deleteItemId);
    const filteredItems = items.filter(x => x.id !== deleteItemId);
    setItems(filteredItems);
    setDeleteItemId(0);
  };


  return (
    <div className="training-management-wrapper">
      <div className='training-management-container'>
        <div className='training-management-header'>
          <div className='training-management-header-row'>
            <button onClick={() => navigate('/')}
              className='training-management-back-btn'>Back</button>
            <h1>Training Area</h1>
          </div>
          <div className='training-management-header-row'>
            {
              trainingArea.items.length > 0 &&
              <input onChange={(e) => onSearch(e.target.value)}
                type='text'
                placeholder='Search' />
            }

            <button onClick={() => navigate('/training-management/0')}
              className='training-management-add-btn'>Add</button>
          </div>
        </div>
        {
          trainingArea.isLoading && <Loading />
        }
        {
          items.length === 0 && !trainingArea.isLoading && <h3>No items available</h3>
        }
        {
          items.length > 0 && !trainingArea.isLoading &&
          <table className='training-items'>
            <thead>
              <tr>
                <th>Name</th>
                <th>Category</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                items.map(i => {
                  return (
                    <tr key={i.id}>
                      <td onClick={() => navigate(`/training-management/${i.id}`)}>{i.name}</td>
                      <td onClick={() => navigate(`/training-management/${i.id}`)}>{i.trainingAreaName}</td>
                      <td className='actions'>
                        <span>
                          <img onClick={() => navigate(`/training-management/${i.id}`)}
                            src='assets/edit-icon.svg' />
                        </span>
                        <span>
                          <img onClick={() => setDeleteItemId(i.id)}
                            src='assets/bin.png' />
                        </span>
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        }
        {
          deleteItemId > 0 &&
          <ConfirmationModal continueText='Yes'
            onCancel={() => setDeleteItemId(0)}
            cancelText='No'
            onContinue={() => onDeleteItem()}
            description='Are you sure you want to delete this item?'
            title='Delete item' />
        }

        <Pagination pageSize={PAGE_SIZE}
          currentPage={currentPage}
          changePage={(page: number) => onPageChange(page)}
          totalRecords={trainingArea.totalRecords} />
      </div>
    </div>
  );
};

export default connector(TrainingManagementPage);
