import * as React from 'react';
import { useState } from 'react';
import { SeasonalContent as SeasonalContentModel } from '../../../store/DailyForecast/DailyForecast';
import { ImageViewer } from '../../Shared/ImageViewer/ImageViewer';
import SeasonalRecipeContent from './SeasonalRecipeContent';


type IProps = {
  content: SeasonalContentModel[],
};

const SeasonalRecipeContentList: React.FC<IProps> = ({ content }) => {
  const [displayImgViewer, setDisplayImgViewer] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string[]>([]);

  const handleImageClick = (image: string[]) => {
    setSelectedImage(image);
    setDisplayImgViewer(true);
  };

  return (
    <>
      {content[0]?.featuredImage &&
        <div className={'seasonal-recipe-content-wrapper show'}>
          <div className="items-wrapper">
            {content.map((item, index) => (
              <SeasonalRecipeContent key={index}
                content={item}
                onImageClick={handleImageClick}
              />
            ))}
          </div>
        </div>
      }

      {displayImgViewer &&
         <ImageViewer handleToggleImgView={() => setDisplayImgViewer(!displayImgViewer)}
           content={selectedImage}
         />
      }
    </>
  );
};

export default SeasonalRecipeContentList;
