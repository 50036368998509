import * as React from 'react';
import { ImageViewer } from '../Shared/ImageViewer/ImageViewer';
import { useState } from 'react';
import { Loading } from '../Shared/Loading/Loading';
import TrainingCard from './TrainingCard';
import { TrainingAreaItem } from '../../store/TrainingArea/TrainingArea';

type IProps = {
  item: TrainingAreaItem,
};

const TrainingAreaItemCard: React.FC<IProps> = ({ item }) => {
  const [displayImgViewer, setDisplayImgViewer] = useState(false);

  return(
    <>
      {
        item?.thumbnail && 
              <div
                onClick={() => setDisplayImgViewer(!displayImgViewer)}>
                <TrainingCard key={item.id}
                  title={item.name}
                  imageSrc={item.thumbnail} 
                  className='training-card-img-catalog'/>
              </div>
      }
      {
        displayImgViewer  &&
            <ImageViewer handleToggleImgView={() => setDisplayImgViewer(!displayImgViewer)}
              content={[item.content]} />
      }
    </>
  );
};

export default TrainingAreaItemCard;
