import React from 'react';
import MenuBar from '../MenuBar/MenuBar';
import HeaderBar from '../HeaderBar/HeaderBar';
import FooterBar from '../FooterBar/FooterBar';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { AuthManager } from '../../../AuthManager';
import { Loading } from '../Loading/Loading';
import { ApplicationState } from '../../../store';

import * as SuggestionStore from '../../../store/Suggestion/Suggestion';
import * as AuthenticationStore from '../../../store/Authentication/Authentication';
import { WithRouter } from '../Routing/WithRouter';
import { connect, ConnectedProps } from 'react-redux';

const mapState = (state: ApplicationState) => ({
  suggestion: state.suggestion,
  authentication: state.authentication,
});

const mapDispatch = {
  ...SuggestionStore.actionCreators,
  ...AuthenticationStore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

type IProps = { children?: React.ReactNode } & PropsFromRedux;

function ContentPanel (props: IProps) {  
  const authManager = new AuthManager();
  const { authentication } = props;
    
  return (
    <>
      <AuthenticatedTemplate>
        <HeaderBar/>
      </AuthenticatedTemplate>
      <div className='content-panel'>
        <div className="video-wrapper">
          <img src='/assets/costa-backround.png'/>
        </div> 
        {
          authentication && authentication.isLoading &&
              <Loading overlay={true} />
        }
        {
          authentication && (authentication.tenantDetails || !authManager.isUserAuthentiated()) && !authentication.isLoading &&
                <div className="content-panel-inner">
                  <AuthenticatedTemplate>
                    <MenuBar/> 
                  </AuthenticatedTemplate>
                  <div className='content'>   
                    {props.children}
                  </div>
                </div>
        }
      </div>
      <FooterBar /> 
    </>
  );
}

export default connector(WithRouter(ContentPanel));