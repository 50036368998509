import { useState } from 'react';
import SwipeableViews, { OnSwitchingCallbackTypeDescriptor } from 'react-swipeable-views';

type Props = {
  content: string[], 
  handleToggleImgView: Function,
}

export const ImageViewer = (props: Props) => {
  const [index, setIndex] = useState(0);
  const [switching, setSwitching] = useState(false);
  
  const handleChangeIndex = (i: number) => {
    setIndex(i);
  };

  const handleSwitching = (index: number, type: OnSwitchingCallbackTypeDescriptor) => {
    if(type == 'end') {
      setSwitching(false);
    } else {
      setSwitching(true);
    }
  };
  
  return (
    <div>      
      {
        props.content && (
          <>
            <div className='backdrop'></div>
            <div className='img-wrapper'>
              <SwipeableViews
                index={index}
                onChangeIndex={handleChangeIndex}
                className="slider"
                enableMouseEvents={true}
                onSwitching={(index, type) => handleSwitching(index, type)}
              >
                {
                  props.content.map(c => 
                  {
                    return (
                      <img
                        key={c}
                        onClick={() => !switching && props.handleToggleImgView()}
                        src={c} />
                    );
                  })
                }
              </SwipeableViews>

              <div className='desktop-slider'>
                {
                  index > 0 &&                 
                        <img onClick={() => handleChangeIndex(index-1)}
                          className='left-chevron'
                          src='assets/left-chevron-white.png' />
                }

                <img
                  key={props.content[index]}
                  onClick={() => !switching && props.handleToggleImgView()}
                  src={props.content[index]} />

                {
                  index < props.content.length - 1 &&
                                    <img onClick={() => handleChangeIndex(index + 1)}
                                      className='right-chevron'
                                      src='assets/right-chevron-white.png' />
                }

              </div>
            </div>

            <div className='pagination'>
              <span>{index + 1} / {props.content.length}</span>
            </div> 
          </>
        )
      }
    </div>
  );
};