import * as React from 'react';
import { ApplicationState } from '../../../store';
import { connect, ConnectedProps } from 'react-redux';
import { WithRouter } from '../../Shared/Routing/WithRouter';
import DatePicker, { ReactDatePicker } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as AuthenticationStore from '../../../store/Authentication/Authentication';
import * as HistoryStore from '../../../store/History/History';
import { Loading } from '../../Shared/Loading/Loading';
import DateUtils from '../../../helpers/DateUtils';
import Suggestion from '../../Suggestion/Suggestion/Suggestion';

const mapState = (state: ApplicationState) => ({
  history: state.history,
  authentication: state.authentication,
});
const mapDispatch = {
  ...HistoryStore.actionCreators,
  ...AuthenticationStore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

type IProps = PropsFromRedux;
type IState = {
  selectedDate: Date,
  isCalendarOpen: boolean
}

class History extends React.PureComponent<IProps, IState> {
  datePickerRef: React.RefObject<ReactDatePicker<never, undefined>> | undefined;

  constructor(props: IProps) {
    super(props);

    this.state = {
      selectedDate: new Date(),
      isCalendarOpen: false,
    };
    this.datePickerRef = React.createRef();
  }

  public componentDidMount() {
    const { requestHistory, authentication } = this.props;

    requestHistory(authentication.selectedScoringEntity, DateUtils.getLocalDateString());
  }

  public handleDateChange(date: Date) {
    const { requestHistory, authentication } = this.props;

    requestHistory(authentication.selectedScoringEntity, date.toDateString());

    this.setState({ selectedDate: date });
  }

  private sortSuggestionsBySeenDate() {
    const { history } = this.props;

    if(history.suggestions.length <= 0) {     
      return;  
    }

    const suggestions = history.suggestions.sort((a, b) => {
      if(a.pinned && !b.pinned) {
        return -1;
      } else if(!a.pinned && b.pinned) {
        return 1;
      } else {
        return new Date(b.seen!).getTime() - new Date(a.seen!).getTime();
      }
    });

    return suggestions;
  }
  
  public render() {
    const { history } = this.props;
    
    const sortedSuggestions = this.sortSuggestionsBySeenDate();

    return(
      <div className="app">
        <div className="body-wrapper">
          <div className='title-wrapper'>
            <h1 className='history-heading title'>History</h1>
            <div className='date-wrapper'>
              <DatePicker className='date-picker'
                selected={this.state.selectedDate}
                onChange={(date: Date) => this.handleDateChange(date)}
                dateFormat="dd/MM/yyyy"
                popperPlacement='bottom'
                ref={this.datePickerRef}
                onCalendarOpen={() => {this.setState({ isCalendarOpen: true });}}
                onCalendarClose={() => {this.setState({ isCalendarOpen: false });}}
              />
              <span className={`arrow ${this.state.isCalendarOpen ? 'up' :'down'}`} 
                onClick={() => this.datePickerRef?.current?.setOpen(true)}
              />
            </div>
          </div>
          {
            history && history.isLoading && <Loading />
          }

          {
            history && !history.isLoading && history.suggestions.length === 0
            && <h3>There are no seen suggestions for this date.</h3>
          }

          {
            sortedSuggestions && sortedSuggestions.map(s => {
              return (
                <div key={s.id + s.timeSlot}
                  className='card-wrapper'>
                  <Suggestion
                    suggestion={s}
                    pinned={s.pinned}
                    blockSwipe={true}
                  />
                </div>
              );
            })
          }
        </div>
      </div>
    );}
}

export default connector(WithRouter(History));
