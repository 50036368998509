import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ApplicationState } from '../../store';
import './ReleaseGuide.scss';
import * as ReleaseStore from '../../store/Release/Release';
import { useNavigate, useParams } from 'react-router-dom';
import DateUtils from '../../helpers/DateUtils';
import { Loading } from '../Shared/Loading/Loading';

const mapState = (state: ApplicationState) => ({
  releaseGuideState: state.releaseGuide,
});

const mapDispatch = {
  ...ReleaseStore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IProps = PropsFromRedux;

const ReleaseGuide: React.FC<IProps> = (props) => {
  const { requestReleaseGuide, releaseGuideState } = props;
  const [releaseContentList, setReleaseContentList] = useState<ReleaseStore.Release[]>([]);
  const { releaseNumber = '' } = useParams<{ releaseNumber?: string }>();
  const [imageLoaded, setImageLoaded] = useState(false);

  const navigate = useNavigate();


  useEffect(() => {
    requestReleaseGuide(DateUtils.getLocalDateString(), releaseNumber);
  }, [releaseNumber, requestReleaseGuide]);

  useEffect(() => {
    if (releaseGuideState && releaseGuideState.content) {
      const filteredContentList = releaseGuideState.content.filter(content => !content.isSummary);
      setReleaseContentList(filteredContentList);
    }
  }, [releaseGuideState]);

 

  const handleViewMoreButtonClick = () => {
    navigate('/release-guides');
  };


  return (
    <div className="app">
      <div className="body-wrapper">
        <div>
          <div>

            <h1 className='release-number'>Release {releaseNumber}</h1>
            {
              releaseGuideState && releaseGuideState.isLoading && imageLoaded && <Loading />
            }
            {
              !releaseGuideState.isLoading && releaseContentList.length>0 && 
              <div className="scrollable-container">
                {releaseContentList.map((content, index) => (
                  <div className="release-content"
                    key={index}>
                    {content.title && <div className="release-title">{content.title}</div>}
                    {content.description && <p >{content.description}</p>}
                    {content.contentImages.map((image, imageIndex) => (
                      <img
                        className="release-image"
                        key={imageIndex}
                        src={image}
                        onLoad={()=>setImageLoaded(true)}
                      />
                    ))}
                  </div>
                ))}
              </div>
            }
          </div>
          <button className="view-more-button"
            onClick={handleViewMoreButtonClick}>
             View more release guides
          </button>
        </div>
      </div>
    </div>
  );


};

export default connector(ReleaseGuide);
