type Props = {
  overlay?: boolean;
};

export const Loading = (props: Props) => {
  return (
    <>
      {props.overlay && <div className="loading-overlay"></div>}
      <div className={`loading-wrapper ${props.overlay ? 'centered' : ''}`}>
        <img src='/assets/loading.svg'/>
      </div>
    </>
  );
};
