import dateFormat from 'dateformat';

class DateUtils {
 
  static dateTimeFormat = 'yyyy-mm-dd\'T\'HH:MM:ss\'Z\'';
  static dateFormat = 'yyyy-mm-dd';
   
  static getLocalDateString = (format?: string) : string => {
    const now = new Date();
    return format ? dateFormat(now, format) : dateFormat(now);
  };

  static getLocalDate = () : Date => {
    return new Date((new Date().setHours(new Date().getHours() - (new Date().getTimezoneOffset() / 60))));
  };

}

export default (DateUtils); 
