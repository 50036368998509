import dateFormat from 'dateformat';
import * as React from 'react';
import Chart from 'react-apexcharts';
import { WithRouter } from '../Routing/WithRouter';
import * as WeatherStore from '../../../store/Weather/Weather';
import * as AuthenticationStore from '../../../store/Authentication/Authentication';
import { ApplicationState } from '../../../store';
import { connect, ConnectedProps } from 'react-redux';
import { weatherForecastOptions, weatherForecastSeries } from './WeatherChartOptions';
import { AssetKeys } from '../../../AssetKeys';
import SunCalc from 'suncalc';

const mapState = (state: ApplicationState) => ({
  weatherDataState: state.weatherData,
  authenticationState: state.authentication,
});

const mapDispatch = {
  ...WeatherStore.actionCreators,
  ...AuthenticationStore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

type IProps = PropsFromRedux;

enum WeatherModes {
  Temperature = 'Temperature',
  CloudCover = 'Cloud Cover'
}

const TemperatureColour = 'rgba(255, 204, 0, 0.5)';
const CloudCoverColour = 'rgba(138, 138, 138, 0.5)';

type IState = {
  selectedWeatherMode: string,
}

const SixHourForecast = 6;

const DayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

class WeatherWidget extends React.PureComponent<IProps, IState> {

  constructor(props: IProps) {
    super(props);

    this.state = {
      selectedWeatherMode: WeatherModes.Temperature,
    };
  }

  componentDidMount() {
    const { weatherDataState, authenticationState, requestWeatherData } = this.props;

    if (!weatherDataState.isLoading && weatherDataState.data.hourly && weatherDataState.data.hourly.temperature_2m.length <= 0) {
      const startDate = dateFormat(Date.now(), 'yyyy-mm-dd');
      const endDate = dateFormat(new Date().setDate(new Date().getDate() + 1), 'yyyy-mm-dd');

      const scoringEntity = authenticationState.scoringEntities && authenticationState.scoringEntities.find(x => x.dataId === authenticationState.selectedScoringEntity);

      if (scoringEntity) {
        requestWeatherData(scoringEntity.latitude, scoringEntity.longitude, startDate, endDate);
      }
    }
  }

  onWeatherModeSelect(mode: string) {
    this.setState({ selectedWeatherMode: mode });
  }

  public getLocalTime() {
    return new Date((new Date().setHours(new Date().getHours() - (new Date().getTimezoneOffset() / 60))));
  }

  getSunriseAndSunsetTimes(scoringEntity: AuthenticationStore.ScoringEntity) : { sunrise: Date, sunset: Date } {
    const times = SunCalc.getTimes(new Date(), scoringEntity.latitude, scoringEntity.longitude);

    return { sunrise: times.sunrise, sunset: times.sunset };   
  }

  getWeatherIcon() {
    const { authenticationState, weatherDataState } = this.props;

    const scoringEntity = authenticationState.scoringEntities && authenticationState.scoringEntities.find(x => x.dataId === authenticationState.selectedScoringEntity);

    if (scoringEntity && weatherDataState.data.current_Weather) {
      const { sunset } =  this.getSunriseAndSunsetTimes(scoringEntity);

      const weatherCode = weatherDataState.data.current_Weather.weathercode;
      const date = new Date();

      // return night version icons 
      if(weatherCode <= 2) {
        if(date > sunset) {
          return AssetKeys.weather_night[weatherDataState.data.current_Weather.weathercode];
        }
      }

      return AssetKeys.weather[weatherDataState.data.current_Weather.weathercode];
    }
  }

  getStartHourIndex() {
    const { weatherDataState } = this.props;

    const localTime = this.getLocalTime();

    const today = dateFormat(localTime, 'yyyy-mm-dd');
    const hour = dateFormat(localTime, 'HH:00:00');

    return weatherDataState.data.hourly.time.indexOf(`${today}T${hour}`);
  }

  getDayOfWeek() {
    const today = new Date();
    return DayNames[today.getDay()];
  }

  initializeWeatherData(weatherMode: string) {
    const { weatherDataState } = this.props;

    if(weatherDataState.data.hourly) {
      const startHourIndex = this.getStartHourIndex();

      if (weatherMode === WeatherModes.Temperature) {
        if (weatherDataState.data.hourly.temperature_2m.length > 0) {

          weatherForecastSeries[0].data = weatherDataState.data.hourly.temperature_2m
            .slice(startHourIndex === 0 ? 0 : startHourIndex - 1, startHourIndex + SixHourForecast + 1);

          weatherForecastOptions.colors = [TemperatureColour];

          if (weatherForecastOptions.yaxis) {
            const yAxis: any = weatherForecastOptions.yaxis;

            yAxis.min = Math.min(...weatherDataState.data.hourly.temperature_2m);
            yAxis.max = Math.max(...weatherDataState.data.hourly.temperature_2m);
          }
        }
      } else {
        if (weatherDataState.data.hourly.cloudCover.length > 0) {
          const startHourIndex = this.getStartHourIndex();

          weatherForecastSeries[0].data = weatherDataState.data.hourly.cloudCover
            .slice(startHourIndex === 0 ? 0 : startHourIndex - 1, startHourIndex + SixHourForecast + 1);

          weatherForecastOptions.colors = [CloudCoverColour];

          if (weatherForecastOptions.yaxis) {
            const yAxis: any = weatherForecastOptions.yaxis;

            yAxis.min = Math.min(...weatherDataState.data.hourly.cloudCover);
            yAxis.max = Math.max(...weatherDataState.data.hourly.cloudCover);
          }
        }
      }

      if (weatherForecastOptions.xaxis) {
        weatherForecastOptions.xaxis.categories = weatherDataState.data.hourly.time
          .slice(startHourIndex === 0 ? 0 : startHourIndex - 1, startHourIndex + SixHourForecast + 1)
          .map(t => dateFormat(t, 'HH'));
      }
    }
  }

  public render() {
    const { authenticationState, weatherDataState } = this.props;
    const { selectedWeatherMode } = this.state;

    this.initializeWeatherData(selectedWeatherMode);

    const weatherCode: number = weatherDataState.data.current_Weather && weatherDataState.data.current_Weather.weathercode;

    return (
      weatherDataState.data.current_Weather ?
        <div className='weather-widget'>
          <div className='widget-header'>
            <div className='header-left'>
              <div className='current-temp'>
                <img src={this.getWeatherIcon()}
                  className='weather-icon' />
                <div className='temp-value'>{weatherDataState.data.current_Weather.temperature}<span>°C</span></div>
              </div>
              <div className='weather-modes'>
                <div onClick={() => this.onWeatherModeSelect(WeatherModes.Temperature)}
                  className={`weather-mode ${selectedWeatherMode === WeatherModes.Temperature ? 'active' : ''}`}>Temperature</div>
                <div className='mode-division'>|</div>
                <div onClick={() => this.onWeatherModeSelect(WeatherModes.CloudCover)}
                  className={`weather-mode ${selectedWeatherMode === WeatherModes.CloudCover ? 'active' : ''}`}>Cloud Cover</div>
              </div>
            </div>
            <div className='header-right'>
              <div className='location'>{authenticationState.scoringEntities &&
              authenticationState.scoringEntities.find(x => x.dataId === authenticationState.selectedScoringEntity)?.name}
              </div>
              <div className='weekday'>{this.getDayOfWeek()}</div>
              <div className='weather-description'>{WeatherStore.WeatherDescriptions[weatherCode]}</div>
            </div>
          </div>
          <div className="weather-chart">
            {
              selectedWeatherMode === WeatherModes.Temperature &&
              <Chart
                options={weatherForecastOptions}
                series={weatherForecastSeries}
                type="area"
                height={200}
              />
            }
            {
              selectedWeatherMode === WeatherModes.CloudCover &&
              <Chart
                options={weatherForecastOptions}
                series={weatherForecastSeries}
                type="area"
                height={200}
              />
            }
          </div>
        </div>
        :
        <div></div>
    );
  }
}

export default connector(WithRouter(WeatherWidget));
