/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import {
  ActionAnimations,
  SwipeableList,
  SwipeableListItem,
} from '@sandstreamdev/react-swipeable-list';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';
import { ApplicationState } from '../../../store';
import * as SuggestionStore from '../../../store/Suggestion/Suggestion';
import * as AuthenticationStore from '../../../store/Authentication/Authentication';
import { connect, ConnectedProps } from 'react-redux';
import { WithRouter } from '../../Shared/Routing/WithRouter';
import DateUtils from '../../../helpers/DateUtils';
import Suggestion from '../../Suggestion/Suggestion/Suggestion';
import { AssetKeys } from '../../../AssetKeys';


const mapState = (state: ApplicationState) => ({
  suggestionState: state.suggestion,
  authentication: state.authentication,
});

const mapDispatch = {
  ...SuggestionStore.actionCreators,
  ...AuthenticationStore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

type IProps = {
  adminMode?: boolean,
  selectedScoringEntity?: string,
  handleAdminViewBack?: Function
} & PropsFromRedux;

interface IState {
  swipeCardProgress: number,
  showCardSwipeIcons: boolean,
  showCardSwipeText: boolean,
  userIsSwiping: boolean,
  displayActualCardContent: boolean,
  trainingSuggestions: SuggestionStore.Suggestion[],
  selectedScoringEntity: string,
  nextCardSuggestionIndex: number,
}

class TrainingSuggestion extends React.PureComponent<IProps, IState> {

  // static variables
  maxStackedCards = 3;
  showCardSwipeIconsThreshold = 15; // percentage
  showCardSwipeTextThreshold = 35; // percentage

  constructor(props: IProps) {
    super(props);
    this.state = {
      swipeCardProgress: 0,
      showCardSwipeText: false,
      showCardSwipeIcons: false,
      userIsSwiping: false,
      displayActualCardContent: true,
      trainingSuggestions: [],
      selectedScoringEntity: '',
      nextCardSuggestionIndex: 1,
    };
  }

  public componentDidMount() {
    const { suggestionState } = this.props;
    this.setState({ trainingSuggestions: suggestionState.trainingSuggestions });
  }

  public handleSwipeEnd() {
    this.setState({ swipeCardProgress: 0 });
    this.setState({ nextCardSuggestionIndex: 0 });
  }

  public handleSwipeStart() {
    this.setState({ nextCardSuggestionIndex: 1 });
  }

  public handleSwipeProgress(progress: number) {
    this.setState({ swipeCardProgress: progress, showCardSwipeText: false, showCardSwipeIcons: false, userIsSwiping: true });

    if (this.state.swipeCardProgress >= this.showCardSwipeIconsThreshold) {
      this.setState({ showCardSwipeIcons: true });
    }

    if (this.state.swipeCardProgress >= this.showCardSwipeTextThreshold) {
      this.setState({ showCardSwipeText: true });
    }
  }

  public handleSuggestionCardAction(actionedSuggestion: SuggestionStore.Suggestion, value?: boolean) {
    const { trainingSuggestions } = this.state;
    const { authentication } = this.props;

    if (trainingSuggestions.length === 1) {
      const { createTrainingSuggestion } = this.props;

      const data = {
        storeId: authentication.selectedScoringEntity,
        title: trainingSuggestions[0].title,
        description: trainingSuggestions[0].description,
        date: DateUtils.getLocalDateString(DateUtils.dateTimeFormat),
        helpful: value,
        actioned: true,
        complete: true,
        obscurity: 1,
        priority: 1,
        type: 'Training',
        category: 'Training',
      };
      createTrainingSuggestion(data);
    }

    const suggestionIndex = trainingSuggestions.findIndex(x => x.id === actionedSuggestion.id);
    trainingSuggestions.splice(suggestionIndex, 1);

    if (trainingSuggestions.length === 0) {
      this.requestSuggestions();
    }

    this.setState({ trainingSuggestions });
  
 
  
  }

  requestSuggestions() {
    const { resetDisplayTrainingSuggestion } = this.props;
    setTimeout(() => resetDisplayTrainingSuggestion(), 250);
  }


  renderSuggestionStack() {
    const stackedCards = this.state.trainingSuggestions.slice(1, this.maxStackedCards + 1)
      .map((s, i) => <div key={s.id}
        className={`card card-${i + 1}`}></div>);

    return stackedCards.length > 0 && (
      <div className='stacked-cards'>
        {stackedCards}
      </div>
    );
  }

  lastCardSwipeLeftContent() {
    return (<div className='swipe-content'>
      {
        <img className={this.state.showCardSwipeIcons ? 'visible' : ''}
          src={AssetKeys.thumbs_down_icon} />
      }
      <div className={`content-text ${this.state.showCardSwipeText ? 'visible' : ''}`}>Bad Training Suggestions</div>
    </div>);
  }

  lastCardSwipeRightContent() {
    return (
      <div className='swipe-content'>
        {
          <img className={this.state.showCardSwipeIcons ? 'visible' : ''}
            src={AssetKeys.thumbs_up_icon} />
        }
        <div className={`content-text ${this.state.showCardSwipeText ? 'visible' : ''}`}>Helpful Training Suggestions</div>
      </div>
    );
  }

  public render() {
    const { trainingSuggestions, nextCardSuggestionIndex } = this.state;
    return (
      <div>
        {trainingSuggestions && trainingSuggestions.length > 0 &&
          (
            this.props.adminMode ?
              <Suggestion
                suggestion={trainingSuggestions[0]}
                blockSwipe={true}
              />
              :
              <SwipeableList threshold={0.5}>
                {({
                  className,
                  scrollStartThreshold,
                  swipeStartThreshold,
                }) => (
                  <TransitionGroup
                    className={className}
                    enter={true}
                    exit={true}
                  >
                    <CSSTransition
                      classNames="my-node"
                      timeout={3500}
                    >
                      {trainingSuggestions.length === 1
                        ?
                        (
                          <SwipeableListItem
                            scrollStartThreshold={scrollStartThreshold}
                            key={trainingSuggestions[0].id}
                            swipeLeft={{
                              content: this.lastCardSwipeLeftContent(),
                              actionAnimation: ActionAnimations.REMOVE,
                              action: () => this.handleSuggestionCardAction(trainingSuggestions[0], false),
                            }}
                            swipeRight={{
                              content: this.lastCardSwipeRightContent(),
                              actionAnimation: ActionAnimations.REMOVE,
                              action: () => this.handleSuggestionCardAction(trainingSuggestions[0], true),
                            }}

                            swipeStartThreshold={swipeStartThreshold}
                            onSwipeProgress={(progress) => { this.handleSwipeProgress(progress); }}
                            onSwipeEnd={() => this.handleSwipeEnd()}
                          >
                            <div className='suggestion'>
                              <h3 className="title">{trainingSuggestions[0].title}</h3>
                              <p className="desc">{trainingSuggestions[0].description}</p>
                            </div>
                          </SwipeableListItem>
                        )
                        :
                        <>
                          <SwipeableListItem
                            scrollStartThreshold={scrollStartThreshold}
                            swipeRight={{
                              content: <div className='suggestion'>
                                <h3 className="title">{trainingSuggestions[nextCardSuggestionIndex].title}</h3>
                                <p className="desc">{trainingSuggestions[nextCardSuggestionIndex].description}</p>
                              </div>,
                              actionAnimation: ActionAnimations.REMOVE,
                              action: () => this.handleSuggestionCardAction(trainingSuggestions[0]),
                            }}
                            swipeLeft={{
                              content: <div className='suggestion'>
                                <h3 className="title">{trainingSuggestions[nextCardSuggestionIndex].title}</h3>
                                <p className="desc">{trainingSuggestions[nextCardSuggestionIndex].description}</p>
                              </div>,
                              actionAnimation: ActionAnimations.REMOVE,
                              action: () => this.handleSuggestionCardAction(trainingSuggestions[0]),
                            }}

                            swipeStartThreshold={swipeStartThreshold}
                            onSwipeProgress={(progress) => { this.handleSwipeProgress(progress); }}
                            onSwipeEnd={() => this.handleSwipeEnd()}
                            onSwipeStart={() => this.handleSwipeStart()}
                          >
                            <div className='suggestion'>
                              <h3 className="title">{trainingSuggestions[0].title}</h3>
                              <p className="desc">{trainingSuggestions[0].description}</p>
                            </div>
                          </SwipeableListItem>

                        </>
                      }
                    </CSSTransition>
                  </TransitionGroup>
                )}
              </SwipeableList>
          )
        }
        {
          this.renderSuggestionStack()
        }
      </div>

    );
  }
}
export default connector(WithRouter(TrainingSuggestion));



