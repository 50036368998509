import * as React from 'react';
import { ApplicationState } from '../../../store';
import { connect, ConnectedProps } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import * as AuthenticationStore from '../../../store/Authentication/Authentication';
import * as TrainingAreaStore from '../../../store/TrainingArea/TrainingArea';
import { Loading } from '../../Shared/Loading/Loading';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AssetKeys } from '../../../AssetKeys';

const mapState = (state: ApplicationState) => ({
  trainingArea: state.trainingArea,
});

const mapDispatch = {
  ...AuthenticationStore.actionCreators,
  ...TrainingAreaStore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

type IProps = PropsFromRedux;

type ItemParams = {
  itemId: string;
}

const emptyItem: TrainingAreaStore.TrainingAreaItem = {
  id: 0,
  name: '',
  content: '',
  thumbnail: '',
  trainingAreaId: 0,
  trainingAreaName: '',

};

const TrainingItemManagementPage = (props: IProps) => {
  const [item, setItem] = useState<TrainingAreaStore.TrainingAreaItem>(emptyItem);
  const { itemId } = useParams<ItemParams>();
  const { trainingArea, requestTrainingAreaItem, requestTrainingAreas, updateTrainingAreaItem, addTrainingAreaItem } = props;

  const navigate = useNavigate();

  useEffect(() => {
    if (itemId && parseInt(itemId) > 0) {
      requestTrainingAreaItem(parseInt(itemId));
    }

    requestTrainingAreas();
  }, []);

  useEffect(() => {
    if (trainingArea.item) {
      setItem(trainingArea.item);
    }

  }, [trainingArea.item]);

  useEffect(() => {
    if (trainingArea.errorMessage.length > 0) {
      toast.error(trainingArea.errorMessage);
    }

  }, [trainingArea.errorMessage]);

  useEffect(() => {
    if (trainingArea.isUpdated) {
      toast.success(`Item successfully ${item.id > 0 ? 'updated' : 'added'}!`);

      if (item.id === 0) {
        navigate('/training-management');
      }
    }

  }, [trainingArea.isUpdated]);

  const onNameUpdate = (value: string) => {
    setItem({ ...item, name: value });
  };

  const onAreaUpdate = (areaId: string) => {
    setItem({ ...item, trainingAreaId: parseInt(areaId) });
  };


  const onImageSelect = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
    if (event) {
      const file = event.target.files?.[0] || null;
      if (file) {
        const maxSizeInBytes = 1 * 1024 * 1024; // 1MB in bytes
        if (file.size > maxSizeInBytes) {
          toast.error('Image size exceeds 1MB. Please select a smaller image.');
          return;
        }

        const reader = new FileReader();

        reader.onload = (readerEvent) => {
          const contents = readerEvent.target?.result as string;

          if (type === 'thumbnail') {
            // resize the image to 250x250
            const img = new Image();
            img.src = contents;

            img.onload = () => {
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');

              canvas.width = 250;
              canvas.height = 250;

              if (ctx) {
                ctx.drawImage(img, 0, 0, 250, 250);
                const resizedDataURL = canvas.toDataURL();

                setItem({ ...item, thumbnail: resizedDataURL });
              }
            };
          } else {
            setItem({ ...item, content: contents });
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const getImageContent = (type: string) => {
    const isThumbnailType = type === 'thumbnail';
    const itemContent = isThumbnailType ? item.thumbnail : item.content;

    if (itemContent) {
      return itemContent;
    } else if (trainingArea.isLoading) {
      return AssetKeys.loading;
    } else {
      return AssetKeys.plus_icon;
    }
  };


  const onSave = () => {
    // convert to base64 for backend to store as image
    const formattedItem = { ...item };
    formattedItem.thumbnail = formattedItem.thumbnail?.split(',')[1];
    formattedItem.content = formattedItem.content?.split(',')[1];

    if (formattedItem.id > 0) {
      updateTrainingAreaItem(formattedItem);
    } else {
      addTrainingAreaItem(formattedItem);
    }
  };

  return (
    <div className="training-item-management-wrapper">
      <div className='training-item-management-container'>
        <h1>{`${itemId && parseInt(itemId) > 0 ? 'Edit' : 'Add'} Training Item`}</h1>
        {
          trainingArea.isLoading && <Loading />
        }
        {
          !trainingArea.isLoading &&
          <>
            <form>
              <div className='training-item-management-content'>
                <div className='training-item-management-column'>
                  <div className='training-item-management-input'>
                    <label>Training Item Name</label>
                    <input onChange={(e) => onNameUpdate(e.target.value)}
                      type='text'
                      value={item.name}
                      placeholder='Input name' />
                  </div>
                  <div className='training-item-management-input'>
                    <label>Training Item Category</label>
                    <select value={item.trainingAreaId}
                      onChange={(e) => onAreaUpdate(e.target.value)}>
                      <option value=''>Select a category</option>
                      {
                        trainingArea.areas.map((a) => {
                          return (
                            <option key={a.id}
                              value={a.id}>{a.name}</option>
                          );
                        })
                      }
                    </select>
                  </div>
                </div>
                <div className='training-item-management-image'>
                  <label htmlFor="thumbnail">
                    <div className='image-label'>Thumbnail Image</div>
                    <img className={`${item?.thumbnail ? '' : 'sm'}`}
                      src={getImageContent('thumbnail')} />
                  </label>
                  <input type="file"
                    id='thumbnail'
                    onChange={(e) => onImageSelect(e, 'thumbnail')}
                    accept="image/*" />
                </div>

                <div className='training-item-management-image'>
                  <label htmlFor="content">
                    <div className='image-label'>Instructions Image</div>
                    <img className={`${item?.content ? '' : 'sm'}`}
                      src={getImageContent('content')} />
                  </label>
                  <input type="file"
                    id='content'
                    onChange={(e) => onImageSelect(e, 'content')}
                    accept="image/*" />
                </div>
              </div>
              <div className='training-item-management-footer'>
                <button onClick={() => navigate('/training-management')}>Cancel</button>
                <button disabled={item.name?.length <= 0 || item.name?.length > 50 || item.thumbnail?.length <= 0 || item.content?.length <= 0 || item.trainingAreaId <= 0}
                  onClick={() => onSave()}>Save</button>
              </div>
            </form>

          </>
        }

      </div>
    </div>
  );
};

export default connector(TrainingItemManagementPage);
