import * as React from 'react';
import { ApplicationState } from '../../../store';
import * as SuggestionStore from '../../../store/Suggestion/Suggestion';
import * as AuthenticationStore from '../../../store/Authentication/Authentication';
import { connect, ConnectedProps } from 'react-redux';
import { WithRouter } from '../../Shared/Routing/WithRouter';
import Input from '../../Shared/Input/Input';
import ConfirmationModal from '../../Shared/ConfirmationModal/ConfirmationModal';
import Textarea from '../../Shared/TextArea/TextArea';
import dateFormat from 'dateformat';
import DateUtils from '../../../helpers/DateUtils';

const mapState = (state: ApplicationState) => ({
  suggestionState: state.suggestion,
  authentication: state.authentication,
});

const mapDispatch = {
  ...SuggestionStore.actionCreators,
  ...AuthenticationStore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

type TProps = { toogleIsSuggestionMode: () => {}, selectedStore: AuthenticationStore.ScoringEntity }
type IProps = PropsFromRedux & TProps;
    
interface IState {
    id: number;
    title: string;
    description: string;
    category: string;
    obscurity: string;
    priority: string;
    showBefore: string;
    formIsSubmitted: boolean;
    showConfirmationModal: boolean;
    errors: Errors;
    formIsValid: boolean;
    timeSlots: TimeSlot[];
    selectedTime: string;
}

interface Errors {
    title: string;
    description: string;
    category: string;
    obscurity: string,
    priority: string,
}

interface TimeSlot { content: string; value: string; }

class SuggestionForm extends React.PureComponent<IProps, IState> {

  constructor(props: IProps) {
    super(props);

    this.state = {
      id: 0,
      title: '',
      description: '',
      category: '',
      obscurity: '',
      priority: '',
      showBefore: '',
      formIsSubmitted: false,
      showConfirmationModal: false,
      formIsValid: false,
      timeSlots: [],
      selectedTime: '',
      errors:{
        title: '',
        description: '',
        category: '',
        obscurity: '',
        priority: '', 
      },
    };
  }

  EXPAND_WORKING_HOURS = 1;

  public componentDidMount() {
    this.generateTimeSlots();
  }

  public onCreateSuggestion(event: React.FormEvent<HTMLFormElement> ) {
    event.preventDefault();
    this.setState({ formIsSubmitted: true });
    this.validateForm();
  }

  public validateForm() {
    const { title, description, category, priority, obscurity, errors } = this.state;
    const formFields = { title, description, category, priority, obscurity };
    for (const [key] of Object.entries(formFields)) {
      /* eslint-disable  @typescript-eslint/no-explicit-any */
      if((formFields as any)[key].length === 0){
        (errors as any)[key] = 'Field is required';
      }
    }

    this.setState({ ...formFields, errors });

    Object.values(errors).every((error) => error.length===0) ?
      this.setState({ showConfirmationModal: true }) :
      this.setState({ showConfirmationModal: false });
  }

  private timeSlotToDate(timeSlot: string) {
    const now = new Date();

    now.setHours(parseInt(timeSlot.slice(0, 2)));
    now.setMinutes(parseInt(timeSlot.slice(2, 4)));
    now.setSeconds(0);
    now.setMilliseconds(0);

    return new Date((now.setHours(now.getHours() - (now.getTimezoneOffset() / 60))));

    // return dateFormat(now);
  }

  public onConfirmSuggestionCreate() {
    const { createSuggestion } = this.props;
    const { title, description, category, priority, obscurity, selectedTime } = this.state;

    const showBefore = new Date(this.timeSlotToDate(this.state.showBefore));
    const data = { 
      title, 
      description, 
      date: DateUtils.getLocalDateString(DateUtils.dateFormat),
      category,
      priority, 
      obscurity, 
      showBefore, 
      type: 'Manual', 
      timeSlot: `${dateFormat(Date.now(), 'GMT:yyyymmdd')}|${selectedTime.replace('-', '')}`, 
      storeId: this.props.selectedStore.dataId, 
    };
    createSuggestion(data);
    this.setState({ showConfirmationModal: false });
    this.props.toogleIsSuggestionMode();
  }

  private expandWorkingHours(time:string, addHour:number) {
    const [hourAsString, minutesAsString] = time.split('-');
    const date = new Date();
    date.setHours(Number(hourAsString), Number(minutesAsString), 0);
    const hour = date.getHours() + addHour;
    const minutes = date.getMinutes();
    return `${hour < 10 ?  '0' + hour : hour }-${minutes < 10 ? '0' + minutes : minutes}`;
  }

  public generateTimeSlots() {
    const { selectedStore } = this.props;
    let startHour = selectedStore.openHour;
    let endHour = selectedStore.closeHour;
    if( new Date().getDay() === 6) {
      startHour = selectedStore.openHourSun;
      endHour = selectedStore.closeHourSun;
    }

    startHour = this.expandWorkingHours(startHour, -this.EXPAND_WORKING_HOURS);
    endHour = this.expandWorkingHours(endHour, this.EXPAND_WORKING_HOURS);

    startHour = startHour.replace(':','');
    endHour = endHour.replace(':','');
    const formatedEndDate = endHour;
    this.setState({ selectedTime: startHour });
    const times = [] as TimeSlot[];
    for (let i = Number(`${startHour[0]}${startHour[1]}`); i < Number(`${endHour[0]}${endHour[1]}`); i++) {
      for (let j = 0; j < 2; j++) {
        times.push({
          content: `${i < 10 ? '0' + i: i}:${j === 0 ? '00' : 30 * j}`,
          value: `${i < 10 ? '0' + i: i}${j === 0 ? '00' : 30 * j}`,
        });
      }
    }

    if (Number(times[0].value) < Number(startHour)) {
      times.shift();
    }
    if (Number(times[times.length - 1].value) < Number(endHour) && Number(endHour) - Number(times[times.length - 1].value) === 100) {
      times.push({ content: `${endHour[0]}${endHour[1]}:00`, value: (Number(endHour)-30).toString() },
        { content: formatedEndDate, value: endHour },
      );
    }
    this.setState({ timeSlots: times }); 
  }
 
  public handleChange( event: React.ChangeEvent<HTMLSelectElement>, type: string) {
    if(type === 'timeSlot') {
      this.setState({ selectedTime: event.target.value });
    } else {
      this.setState({ showBefore: event.target.value });
    }
    
  }

  public handleTextInputUpdate(value: React.ChangeEvent<HTMLInputElement> | string, fieldName: string) {
    const { errors } = this.state;
    switch (fieldName) {
    case 'title': 
      errors.title = 
          (value as string).length > 100
            ? '100 characters allowed':
            (value as string).length === 0  ? 'Field is required'
              : '';
      break;
    case 'description': 
      errors.description =  
        (value as string).length > 500
          ? '500 characters allowed':
          (value as string).length === 0  ? 'Field is required'
            : '';
      break;
    case 'category':
      errors.category =  
        (value as string).length > 500
          ? '100 characters allowed':
          (value as string).length === 0  ? 'Field is required'
            : '';
      break;
    case 'priority': 
      errors.priority = 
          Number(value) > 0 && Number(value) > 10
            ? 'Value must be between 0 and 10':
            (value as string).length === 0  ? 'Field is required'
              : '';
      break;
    case 'obscurity': 
      errors.obscurity = 
          Number(value) > 0 && Number(value) > 10
            ? 'Value must be between 0 and 10':
            (value as string).length === 0  ? 'Field is required'
              : '';
      break;
    default:
      break;
    }
    const update: object = { errors, [fieldName]: value };
    this.setState(update);
  }

  public render() {
    const { title, description, category, priority, obscurity, showConfirmationModal, timeSlots, errors } = this.state;
    return (
      <div className="app">
        <div className="body-wrapper">
          <div className='suggestion-form'>
            <form onSubmit={(e) => this.onCreateSuggestion(e)}>
              <h3>Add Suggestion</h3>
              <div className='input-wrapper'>
                <Input
                  name="title"
                  label=""
                  placeholderText="Title"
                  inputType="text"
                  onChange={(e: string) => this.handleTextInputUpdate(e, 'title')}
                  value={title}
                />
                {errors.title.length > 0 && 
                            <span className='error'>{errors.title}</span>}
              </div>
              <div className='input-wrapper'>
                <Textarea
                  name="description"
                  label=""
                  placeholderText="Description"
                  onChange={(e: string) => this.handleTextInputUpdate(e, 'description')}
                  value={description}
                />
                {errors.description.length > 0 && 
                            <span className='error'>{errors.description}</span>}
              </div>
              <div className='input-wrapper'>
                <Input
                  name="category"
                  label=""
                  placeholderText="Category"
                  inputType="text"
                  onChange={(e: string) => this.handleTextInputUpdate(e, 'category')}
                  value={category}
                />
                {errors.category.length > 0 && 
                            <span className='error'>{errors.category}</span>}
              </div>
              <div className='input-wrapper'>
                <Input
                  name="priority"
                  label=""
                  placeholderText="Priority Score"
                  inputType="number"
                  onChange={(e: string) => this.handleTextInputUpdate(e, 'priority')}
                  value={priority}
                />
                {errors.priority.length > 0 && 
                            <span className='error'>{errors.priority}</span>}
              </div>
              <div className='input-wrapper'>
                <Input
                  name="obscurity"
                  label=""
                  placeholderText="Obscurity Score"
                  inputType="number"
                  onChange={(e: string) => this.handleTextInputUpdate(e, 'obscurity')}
                  value={obscurity}
                />
                {errors.obscurity.length > 0 && 
                            <span className='error'>{errors.obscurity}</span>}
              </div>
              <div className='select-wrapper'>
                <label htmlFor="showBefore">Show Before:</label>
                <select name="showBefore"
                  id="showBefore"
                  onChange={(e) => this.handleChange(e, 'showBefore')}>
                  <option value=''></option>
                  {timeSlots.map((time: TimeSlot) => {
                    return (<option value={time.value}
                      key={time.value}>{time.content}</option>);
                  })}
                </select>
              </div>
              <div className='select-wrapper'>
                <label htmlFor="timeSlot">Choose a time slot:</label>

                <select name="timeSlot"
                  id="timeSlot"
                  onChange={(e) => this.handleChange(e, 'timeSlot')}>
                  {timeSlots.map((time: TimeSlot) => {
                    return (<option value={time.value}
                      key={time.value}>{time.content}</option>);
                  })}
                </select>
              </div>
              <div>
                <button className='form-btn'
                  type='submit'>Save</button>
              </div>
            </form> 
          </div>
        </div>
        { showConfirmationModal &&
            <ConfirmationModal
              onCancel={() => this.setState({ showConfirmationModal:false })}
              onContinue={() => this.onConfirmSuggestionCreate()}
              cancelText='Cancel'
              continueText='Continue'
              description='This will publish the suggestion to the selected store.'
              title='Are you sure?'
            />
        }
      </div>
    );}
}

export default connector(WithRouter(SuggestionForm));
