import { ApplicationState } from '../../../store';
import { SignOutButton } from '../../Login/SignOutButton';
import * as AuthenticationStore from '../../../store/Authentication/Authentication';
import { connect, ConnectedProps } from 'react-redux';
import { WithRouter } from '../Routing/WithRouter';

const mapState = (state: ApplicationState) => ({
  authentication: state.authentication,
});

const mapDispatch = {
  ...AuthenticationStore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

type IProps = PropsFromRedux;

function HeaderBar (props: IProps) {
  const { authentication } = props;

  return(
    <header className="header-bar">
      <div className="inner-container">
        <p className="header-text">{ authentication.scoringEntities && authentication.scoringEntities.length > 1 
          ? 
          'Multiple Stores' 
          : 
          authentication.scoringEntities && authentication.scoringEntities.find(x => x.dataId === authentication.selectedScoringEntity)?.name}
        </p>
        <div className='right-section'>
          <SignOutButton/>
        </div>
      </div>
    </header>
  );
}

export default connector(WithRouter(HeaderBar));
