import * as React from 'react';
import { AssetKeys } from '../../../AssetKeys';

type IProps = {
  body: string,
  type: string,
};

type ImageMapping = {
  // the key is a string (the type), and the value is also a string (the image source)
  [key: string]: string; 
}

const imageMapping: ImageMapping = {
  alert: AssetKeys.reminder.exclamationMark,
  notification: AssetKeys.reminder.info,
  update: AssetKeys.reminder.star,
};

export const ContentBanner: React.FC<IProps> = ({ body , type }) => {
  const imgSrc = imageMapping[type] || ''; 
  return(
    <div className='content-banner'>
      {
        imgSrc != null &&  <div className='bg-black' > <img src={imgSrc}/> </div>
      }
        
      <div className='content'>
        <p>{body}</p>
      </div>

    </div>
  );
};
