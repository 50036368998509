const AzureADClientIdVersions = {
  'test': '3769ef0b-adb5-4cc7-8701-5e685b12cd14',
  'uat': '2703198a-3e71-4564-b0d1-5c2c23d7d075',
  'prod': 'c7befef9-e59d-49cf-90b5-1724bc6e9503',
};

export const MsalConfig = {
  auth: {
    clientId: getClientId(),
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

function getClientId() {
  const domain = window.location.hostname;

  switch(domain) {
  case 'localhost':
  case 'digitalassistant-qa.orderly.io':
    return AzureADClientIdVersions.test;
  case 'digitalassistant-uat.orderly.io':
    return AzureADClientIdVersions.uat;
  default:
    return AzureADClientIdVersions.prod;
  }
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const LoginRequest = {
  forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
  scopes: ['openid', 'profile', 'User.Read'],
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const ApiRequest = {
  forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
  scopes: [`api://${getClientId()}/Data.Access`],
};