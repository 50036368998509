/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppThunkAction } from '../index';
import { Action, Reducer, AnyAction } from 'redux';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface SuggestionState {
  isLoading: boolean;
  isErrored: boolean;
  errorMessage: string;
  isUpdated: boolean;
  displayTrainingSuggestions: boolean;

  suggestions: Suggestion[],
  suggestionsSchedule: Suggestion[]
  adminSuggestions: Suggestion[],
  trainingSuggestions: Suggestion[],
  morningBrewSuggestionSeen: boolean,
}

export interface Suggestion {
  id: number,
  siteID: string,
  timeSlot: string,
  title: string,
  description: string,
  type: string,
  category: string,
  obscurity: number,
  priority: number,
  seen?: Date,
  helpful?: boolean,
  complete?: boolean,
  actioned?: boolean
  dismissed?: Date,
  pinned?: boolean
  subSuggestions?: SubSuggestion[],
}

export interface SubSuggestion {
  id: number,
  suggestionID: string,
  title: string,
  description: string,
  seen?: Date,
  complete?: boolean,
  actioned?: boolean
  dismissed?: Date,
}

export enum SuggestionUpdateType {
  helpful = 1,
  completed = 2
}

export enum SuggestionCategory {
  Celebration = 'Celebration',
  Sales = 'Sales',
  Notification = 'Notification',
  Staffing = 'Staffing',
  Ordering = 'Ordering',
  Compliance = 'Compliance',
  Generic = 'Generic',
  Customer = 'Customer',
  Service = 'Service',
  Quality = 'Quality',
  Energy = 'Energy',
  Inventory = 'Inventory',
  Training = 'Training',
  Reminder = 'Reminder',
}

export enum SuggestionType {
  RealTime = 'realtime',
  Queue = 'queue',
}

export interface SuggestionUpdateModel {
  storeId: string,
  timeslot: string,
  title?: string,
  description?: string,
  type?: string,
  category?: string,
  obscurity?: number,
  priority?: number,
  date?: string,
  id?: number,
  suggestionID?: string,
  seen?: Date,
  helpful?: boolean,
  complete?: boolean,
  actioned?: boolean,
  dismissed?: Date,
  newTimeSlot?: string,
  pinned?: boolean
  subSuggestionId?: number,
}

// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something
// that is going to happen.

export interface RequestSuggestionsAction extends AnyAction {
  type: 'REQUEST_SUGGESTIONS';
}

export interface RequestSuggestionsAsAdminAction extends AnyAction {
  type: 'REQUEST_SUGGESTIONS_AS_ADMIN';
}

export interface ReceiveSuggestionsAction extends AnyAction {
  type: 'RECEIVE_SUGGESTIONS';
  payload: any;
}

export interface RemoveSuggestionAction extends AnyAction {
  type: 'REMOVE_SUGGESTION';
  payload: any;
}

export interface FailedSuggestionsAction extends AnyAction {
  type: 'FAILED_SUGGESTIONS';
}

export interface RequestAdminSuggestionsAction extends AnyAction {
  type: 'REQUEST_ADMIN_SUGGESTIONS';
}

export interface ReceiveAdminSuggestionsAction extends AnyAction {
  type: 'RECEIVE_ADMIN_SUGGESTIONS';
  payload: any;
}

export interface FailedAdminSuggestionsAction extends AnyAction {
  type: 'FAILED_ADMIN_SUGGESTIONS';
}

export interface RequestUpdateSuggestionAction extends AnyAction {
  type: 'REQUEST_UPDATE_SUGGESTION';
}

export interface ReceiveUpdateSuggestionAction extends AnyAction {
  type: 'RECEIVE_UPDATE_SUGGESTION';
  payload: any;
}

export interface ReceiveCreateSuggestionAction extends AnyAction {
  type: 'RECEIVE_CREATE_SUGGESTIONS';
  payload: any;
}
export interface RequestCreateSuggestionAction extends AnyAction {
  type: 'REQUEST_CREATE_SUGGESTIONS';
  payload: any;
}
export interface FailedCreateSuggestionAction extends AnyAction {
  type: 'FAILED_CREATE_SUGGESTIONS';
  payload: any;
}
export interface FailedUpdateSuggestionAction extends AnyAction {
  type: 'FAILED_UPDATE_SUGGESTION';
}
export interface RequestScheduleViewAction extends AnyAction {
  type: 'REQUEST_SCHEDULE_VIEW';
}
export interface RecieveScheduleViewAction extends AnyAction {
  type: 'RECEIVE_SCHEDULE_VIEW';
}
export interface FailedScheduleViewAction extends AnyAction {
  type: 'FAILED_SCHEDULE_VIEW';
}

export interface ReceiveTrainingSuggestionsAction extends AnyAction {
  type: 'RECEIVE_TRAINING_SUGGESTIONS';
  payload: any;
}

export interface FailedTrainingSuggestionsAction extends AnyAction {
  type: 'FAILED_TRAINING_SUGGESTIONS';
}

export interface ReceiveCreateTrainingSuggestionAction extends AnyAction {
  type: 'RECEIVE_CREATE_TRAINING_SUGGESTIONS';
  payload: any;
}
export interface RequestCreateTrainingSuggestionAction extends AnyAction {
  type: 'REQUEST_CREATE_TRAINING_SUGGESTIONS';
  payload: any;
}
export interface FailedCreateTrainingSuggestionAction extends AnyAction {
  type: 'FAILED_CREATE_TRAINING_SUGGESTIONS';
  payload: any;
}
export interface UpdateSuggestionState extends AnyAction {
  type: 'UPDATE_SUGGESTIONS_STATE';
  payload: any;
}
export interface ResetDisplayTrainingSuggestion extends AnyAction {
  type: 'RESET_DISPLAY_TRAINING_SUGGESTION';
  payload: any;
}


// Declare a 'discriminated union' type. This guarantees that all references to 'type'
// properties contain one of the
// declared type strings (and not any other arbitrary string).
export type AreaAction = RequestSuggestionsAction | ReceiveSuggestionsAction | FailedSuggestionsAction | RemoveSuggestionAction | RequestSuggestionsAsAdminAction |
  RequestAdminSuggestionsAction | ReceiveAdminSuggestionsAction | FailedAdminSuggestionsAction |
  RequestUpdateSuggestionAction | ReceiveUpdateSuggestionAction | FailedUpdateSuggestionAction
  | ReceiveCreateSuggestionAction | RequestCreateSuggestionAction | FailedCreateSuggestionAction
  | RequestScheduleViewAction | RecieveScheduleViewAction | FailedScheduleViewAction
  | ReceiveTrainingSuggestionsAction | FailedTrainingSuggestionsAction | ReceiveCreateTrainingSuggestionAction
  | RequestCreateTrainingSuggestionAction | FailedCreateTrainingSuggestionAction | UpdateSuggestionState | ResetDisplayTrainingSuggestion;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger
// a state transition.
// They don't directly mutate state, but they can have external side-effects
// (such as loading data).

export const actionCreators = {
  requestSuggestions: (storeId: string, localDate: string, admin?: boolean):
    AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.suggestion && appState.suggestion.isLoading === false) {
      dispatch({
        type: admin ? 'REQUEST_SUGGESTIONS_AS_ADMIN' : 'REQUEST_SUGGESTIONS',
        http: {
          verb: 'GET',
          endpoint: `/api/suggestions/suggestions?storeId=${storeId}&localDate=${localDate}`,
          successAction: 'RECEIVE_SUGGESTIONS',
          failureAction: 'FAILED_SUGGESTIONS',
        },
      });
    }
  },
  requestAdminSuggestions: (storeId: string, localDate: string):
    AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.suggestion && appState.suggestion.isLoading === false) {
      dispatch({
        type: 'REQUEST_ADMIN_SUGGESTIONS',
        http: {
          verb: 'GET',
          endpoint: `/api/suggestions/adminsuggestions?storeId=${storeId}&localDate=${localDate}`,
          successAction: 'RECEIVE_ADMIN_SUGGESTIONS',
          failureAction: 'FAILED_ADMIN_SUGGESTIONS',
        },
      });
    }
  },
  updateSuggestion: (data: SuggestionUpdateModel):
    AppThunkAction<any> => (dispatch, getState) => {
    dispatch({
      type: 'REQUEST_UPDATE_SUGGESTION',
      http: {
        verb: 'PUT',
        endpoint: '/api/suggestions/suggestion',
        successAction: 'RECEIVE_UPDATE_SUGGESTION',
        failureAction: 'FAILED_UPDATE_SUGGESTION',
        body: {
          ...data,
        },
      },
    });
  },

  updateSuggestionManually: (data: SuggestionUpdateModel):
    AppThunkAction<any> => (dispatch, getState) => {
    dispatch({
      type: 'REQUEST_UPDATE_SUGGESTION',
      http: {
        verb: 'PUT',
        endpoint: '/api/suggestions/manual',
        successAction: 'RECEIVE_UPDATE_SUGGESTION',
        failureAction: 'FAILED_UPDATE_SUGGESTION',
        body: {
          ...data,
        },
      },
    });
  },

  removeSuggestion: (id: number, timeSlot: string):
    AppThunkAction<any> => (dispatch, getState) => {
    dispatch({
      type: 'REMOVE_SUGGESTION',
      payload: {
        id,
        timeSlot,
      },
    });
  },

  deleteSuggestion: (storeId: string, timeslot: string, suggestionId: number):
    AppThunkAction<any> => (dispatch, getState) => {
    dispatch({
      type: 'REQUEST_UPDATE_SUGGESTION',
      http: {
        verb: 'DELETE',
        endpoint: '/api/suggestions/suggestion',
        successAction: 'RECEIVE_UPDATE_SUGGESTION',
        failureAction: 'FAILED_UPDATE_SUGGESTION',
        body: {
          storeId,
          timeslot,
          suggestionId,
        },
      },
    });
  },

  createSuggestion: (data: any):
    AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.suggestion && appState.suggestion.isLoading === false) {
      dispatch({
        type: 'REQUEST_CREATE_SUGGESTION',
        http: {
          verb: 'POST',
          endpoint: '/api/suggestions/createsuggestion',
          successAction: 'RECEIVE_CREATE_SUGGESTIONS',
          failureAction: 'FAILED_CREATE_SUGGESTIONS',
          body: {
            ...data,
          },
        },
      });
    }
  },
  updateSuggestionsState: (suggestions: Suggestion[]):
    AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.suggestion && appState.suggestion.isLoading === false) {
      dispatch({
        type: 'UPDATE_SUGGESTIONS_STATE',
        data: suggestions,
      });
    }
  },
  requestSuggestionsView: (storeId: string):
    AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.suggestion && appState.suggestion.isLoading === false) {
      dispatch({
        type: 'REQUEST_SCHEDULE_VIEW',
        http: {
          verb: 'GET',
          endpoint: `/api/suggestions/viewdailysuggestions?storeId=${storeId}`,
          successAction: 'RECEIVE_SCHEDULE_VIEW',
          failureAction: 'FAILED_SCHEDULE_VIEW',
        },
      });
    }
  },
  requestTrainingSuggestions: ():
    AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.suggestion && appState.suggestion.isLoading === false) {
      dispatch({
        type: 'REQUEST_SUGGESTIONS',
        http: {
          verb: 'GET',
          endpoint: '/api/suggestions/trainingsuggestions',
          successAction: 'RECEIVE_TRAINING_SUGGESTIONS',
          failureAction: 'FAILED_TRAINING_SUGGESTIONS',
        },
      });
    }
  },
  createTrainingSuggestion: (data: any):
    AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.suggestion && appState.suggestion.isLoading === false) {
      dispatch({
        type: 'REQUEST_CREATE_TRAINING_SUGGESTION',
        http: {
          verb: 'POST',
          endpoint: '/api/suggestions/createtrainingsuggestion',
          successAction: 'RECEIVE_CREATE_TRAINING_SUGGESTIONS',
          failureAction: 'FAILED_CREATE_TRAINING_SUGGESTIONS',
          body: {
            ...data,
          },
        },
      });
    }
  }, 
  resetDisplayTrainingSuggestion: ():
  AppThunkAction<any> => (dispatch, getState) => {
  // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.suggestion && appState.suggestion.isLoading === false) {
      dispatch({
        type: 'RESET_DISPLAY_TRAINING_SUGGESTION',
      });
    }
  }, 
};

// REDUCER - For a given state and action, returns the new state. To support time travel,
// this must not mutate the old state.

const unloadedState: SuggestionState = {
  errorMessage: '',
  isErrored: false,
  isLoading: false,
  isUpdated: false,
  displayTrainingSuggestions: false,

  suggestions: [],
  suggestionsSchedule: [],
  adminSuggestions: [],
  trainingSuggestions: [],
  morningBrewSuggestionSeen: false,
};

export const reducer: Reducer<SuggestionState> = (state: SuggestionState | undefined,
  incomingAction: Action): SuggestionState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as AreaAction;
  switch (action.type) {
  case 'REQUEST_SUGGESTIONS':
    return {
      ...unloadedState,
      suggestions: state.suggestions,
      suggestionsSchedule: state.suggestionsSchedule,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
    };
  case 'REQUEST_SUGGESTIONS_AS_ADMIN':
    return {
      ...unloadedState,
      suggestions: [],
      errorMessage: '',
      isErrored: false,
      isLoading: true,
      trainingSuggestions: state.trainingSuggestions,
      displayTrainingSuggestions: state.displayTrainingSuggestions,
    };
  case 'RECEIVE_SUGGESTIONS':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      suggestions: action.payload,
      adminSuggestions: state.adminSuggestions,
      displayTrainingSuggestions: state.displayTrainingSuggestions,
      trainingSuggestions: state.trainingSuggestions,
    };
  case 'FAILED_SUGGESTIONS':
    return {
      ...unloadedState,
      errorMessage: 'An error occurred while receiving the data.',
      isErrored: true,
      isLoading: false,
    };
  case 'REMOVE_SUGGESTION':
    return {
      ...unloadedState,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      suggestions: state.suggestions.filter(x => x.id + x.timeSlot !== action.payload.id + action.payload.timeSlot),

    };
  case 'REQUEST_ADMIN_SUGGESTIONS':
    return {
      ...unloadedState,
      adminSuggestions: state.adminSuggestions,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
    };
  case 'RECEIVE_ADMIN_SUGGESTIONS':
    return {
      ...unloadedState,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      adminSuggestions: action.payload,
    };
  case 'FAILED_ADMIN_SUGGESTIONS':
    return {
      ...unloadedState,
      errorMessage: 'An error occurred while receiving the data.',
      isErrored: true,
      isLoading: false,
    };
  case 'REQUEST_CREATE_SUGGESTIONS':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
    };
  case 'RECEIVE_CREATE_SUGGESTIONS':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
    };
  case 'FAILED_CREATE_SUGGESTIONS':
    return {
      ...state,
      errorMessage: 'An error occurred. ',
      isErrored: true,
      isLoading: false,
    };
  case 'REQUEST_UPDATE_SUGGESTION':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      isUpdated: false,
    };
  case 'RECEIVE_UPDATE_SUGGESTION':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      isUpdated: true,
    };
  case 'FAILED_UPDATE_SUGGESTION':
    return {
      ...state,
      errorMessage: 'An error occurred.',
      isErrored: true,
      isLoading: false,
      isUpdated: false,
    };
  case 'REQUEST_SCHEDULE_VIEW':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
      suggestionsSchedule: [],
    };
  case 'RECEIVE_SCHEDULE_VIEW':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      suggestionsSchedule: action.payload,
    };
  case 'FAILED_SCHEDULE_VIEW':
    return {
      ...state,
      errorMessage: 'An error occurred.',
      isErrored: true,
      isLoading: false,
    };
  case 'RECEIVE_TRAINING_SUGGESTIONS':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      trainingSuggestions: action.payload,
      displayTrainingSuggestions: true,
    };
  case 'FAILED_TRAINING_SUGGESTIONS':
    return {
      ...unloadedState,
      errorMessage: 'An error occurred while receiving the data.',
      isErrored: true,
      isLoading: false,
      displayTrainingSuggestions: false,
    };
  case 'REQUEST_CREATE_TRAINING_SUGGESTIONS':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
    };
  case 'RECEIVE_CREATE_TRAINING_SUGGESTIONS':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      displayTrainingSuggestions: false,
    };
  case 'FAILED_CREATE_TRAINING_SUGGESTIONS':
    return {
      ...state,
      errorMessage: 'An error occurred.',
      isErrored: true,
      isLoading: false,
    };
  case 'UPDATE_SUGGESTIONS_STATE':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      suggestions: action.suggestions === undefined ? [] : action.suggestions,
    };
  case 'RESET_DISPLAY_TRAINING_SUGGESTION':
    return{
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      displayTrainingSuggestions: false,
    };
  default:
    return state;
  }
};

