import React from 'react';
import { Notification } from '../../store/Notification/Notification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPointer, faCircle } from '@fortawesome/free-solid-svg-icons';
import dateFormat from 'dateformat';
import { useNavigate } from 'react-router-dom';

interface NotificationItemProps {
  notification: Notification;
  markNotificationAsRead: (notificationId: number) => void;
   handleClosePanel: () => void;
}

const NotificationItem: React.FC<NotificationItemProps> = ({ notification, markNotificationAsRead, handleClosePanel }) => {
  const navigate = useNavigate();

  const handleReadNotification = () => {
    if (!notification.isRead) 
      markNotificationAsRead(notification.id);
  };

  const handleNotificationClick = () => {
    markNotificationAsRead(notification.id);
    handleClosePanel();
    navigate(notification.link);
  };

  function getFormatDate(dateTimeString: string): string {
    const dateTime = new Date(dateTimeString);
    return dateFormat(dateTime, 'mmmm dd, yyyy');
  }

  return (
    <li className="notification-item">
      <div className='notification-dot-unread'>
        {!notification.isRead ? (
          <FontAwesomeIcon icon={faCircle}
            style={{ color: '#f00000' }}
            onClick={handleReadNotification} 
          />
        ) : <span className='notification-dot-read'> </span>}
      </div>


      <div className="notification-details"
        onClick={handleNotificationClick}
      >
        <div className="notification-title">{notification.title}</div>
        <div className="notification-description">{notification.description}</div>
        <div className="notification-date">{getFormatDate(notification.notificationDate)}</div>
      </div>
      <div className="notification-pointer-wrapper">
        <span className="notification-pointer"
          onClick={handleNotificationClick}> 
          <FontAwesomeIcon icon={faHandPointer}
            size="lg"
            style={{ color: '#c6c7c7' }} 
          />
        </span>
        <span className="notification-tap-here">Tap Here</span> 
      </div>
    </li>
  );
};

export default NotificationItem;
