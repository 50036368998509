import { useState } from 'react';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';
import * as SuggestionStore from '../../../store/Suggestion/Suggestion';
import { Swipeable, SwipeableContent } from '../../Shared/Swipeable/Swipeable';
import { AssetKeys } from '../../../AssetKeys';
import TimedProgressBar from '../../Shared/TimedProgressBar/TimedProgressBar';
import { ANIMATION_DURATION, FULL_SWIPE_THRESHOLD, SUB_SUGGESTION_SWIPE_ACTION_THRESHOLD } from '../SuggestionUtils';


interface Props {
  subSuggestion: SuggestionStore.SubSuggestion,
  category: string,
  onSwipeThresholdCrossed: Function,
  onblockSwipe: Function,
  onRemoveSubSuggestion: Function,
  blockSwipe?: boolean,
  timed?: boolean,
  timerDuration?: number
}

const replacePlaceholder = (str?: string, value?: string) => {
  return str?.replace(/{{.*?}}/, value ?? '');
};

const subSuggestionCategoryContentMap: Record<string, SwipeableContent> = {
  'default': {
    leftContent: {
      imgSrc: AssetKeys.incomplete_action_icon,
      text: 'Step {{num}} not complete',
    },
    rightContent: {
      imgSrc: AssetKeys.complete_action_icon,
      text: 'Step {{num}} complete',
    },
  },
};

const SubSuggestion = (props: Props) => {
  const [swipeCardProgress, setSwipeCardProgress] = useState(0);

  const getSubSuggestionSwipeContent = (category: string): SwipeableContent => {
    const defaultContent = subSuggestionCategoryContentMap['default'];
    const content = subSuggestionCategoryContentMap[category] || defaultContent;

    const leftContent = { ...content.leftContent, text: replacePlaceholder(content.leftContent.text, props.subSuggestion.id.toString()) };
    const rightContent = { ...content.rightContent, text: replacePlaceholder(content.rightContent.text, props.subSuggestion.id.toString()) };
    return { ...content, leftContent, rightContent };
  };

  const handleSwipeProgress = (progress: number) => {
    setSwipeCardProgress(progress);
  };

  const handleSwipeEnd = () => {
    props.onblockSwipe(false);
    if (swipeCardProgress >= FULL_SWIPE_THRESHOLD) {
      setTimeout(() => {
        props.onRemoveSubSuggestion();
      }, ANIMATION_DURATION);
    }
    setSwipeCardProgress(0);
  };

  const handleSubSuggestionSwipeAction = (value: boolean) => {
    if (swipeCardProgress >= FULL_SWIPE_THRESHOLD) {
      props.onSwipeThresholdCrossed(value, props.subSuggestion.id);
    }
  };

  return (
    <>
      {
        <Swipeable
          key={props.subSuggestion.id}
          body={
            <div onMouseDown={() => props.onblockSwipe(true)}
              onTouchStart={() => props.onblockSwipe(true)}
              className='sub-suggestion-wrapper'>
              {
                props.subSuggestion.title &&
                <div className='sub-suggestion-title'
                  
                >
                  {props.subSuggestion.title}
                </div>
              }
              <div className='sub-suggestion-desc'>
                {props.subSuggestion.description}
              </div>
              {
                props.timed && props.timerDuration &&
                <div className='progress-bar-wrapper'>
                  <TimedProgressBar min={0}
                    max={props.timerDuration} />
                </div>
              }
            </div>
          }
          className='sub-suggestion'
          swipeContent={getSubSuggestionSwipeContent(props.category)}
          onSwipeProgress={(progress: number) => handleSwipeProgress(progress)}
          onSwipeThresholdCrossed={(value: boolean) => handleSubSuggestionSwipeAction(value)}
          onSwipeEnd={() => handleSwipeEnd()}
          blockSwipe={props.blockSwipe}
          actionThreshold={SUB_SUGGESTION_SWIPE_ACTION_THRESHOLD}
          removeThreshold={FULL_SWIPE_THRESHOLD}
        />
      }
    </>
  );
};

export default SubSuggestion;
