type IProps = {
  title: string,
  id?: number,
  content: string
  imagePath?: string
};

function DailySuggestionCard (props: IProps) {
  
  const { title, id, content, imagePath } = props;

  return(
    <div className="suggestion-card">
      {
        id != null &&  <div className='bg-black'>{id}</div>

      }
      {
        imagePath != null &&  <div className='bg-black'> <img src={imagePath}/> </div>
      }
        
      <div className='content'>
        <span>{title}</span>
        <p>{content}</p>
      </div>

    </div>
  );
}

export default DailySuggestionCard;
