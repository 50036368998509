/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppThunkAction } from '../index';
import { Action, Reducer, AnyAction } from 'redux';
import { Suggestion } from '../Suggestion/Suggestion';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface HistoryState {
    isLoading: boolean;
    isErrored: boolean;
    errorMessage: string;

    suggestions: Suggestion[],
}

// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something
// that is going to happen.

export interface RequestHistoryAction extends AnyAction {
    type: 'REQUEST_HISTORY';
}

export interface ReceiveHistoryAction extends AnyAction {
    type: 'RECEIVE_HISTORY';
    payload: any;
}

export interface FailedHistoryAction extends AnyAction {
    type: 'FAILED_HISTORY';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type'
// properties contain one of the
// declared type strings (and not any other arbitrary string).
export type AreaAction = RequestHistoryAction | ReceiveHistoryAction | FailedHistoryAction;
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger
// a state transition.
// They don't directly mutate state, but they can have external side-effects
// (such as loading data).

export const actionCreators = {
  requestHistory: (storeId: string, localDate: string):
  AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.history && appState.history.isLoading === false) {
      dispatch({ 
        type: 'REQUEST_HISTORY',
        http: {
          verb: 'GET',
          endpoint: `/api/history/history?storeId=${storeId}&localDate=${localDate}`,
          successAction: 'RECEIVE_HISTORY',
          failureAction: 'FAILED_HISTORY',
        }, 
      });
    }
  },
};

// REDUCER - For a given state and action, returns the new state. To support time travel,
// this must not mutate the old state.

const unloadedState: HistoryState = {
  errorMessage: '',
  isErrored: false,
  isLoading: false,

  suggestions: [],
};

export const reducer: Reducer<HistoryState> = (state: HistoryState | undefined,
  incomingAction: Action):HistoryState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as AreaAction;
  switch (action.type) {
  case 'REQUEST_HISTORY':
    return {
      ...unloadedState,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
      suggestions: [],
    };
  case 'RECEIVE_HISTORY':
    return {
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      suggestions: action.payload,
    };
  case 'FAILED_HISTORY':
    return {
      ...unloadedState,
      errorMessage: 'An error occurred while receiving the data.',
      isErrored: true,
      isLoading: false,
      suggestions: [],
    };
  default:
    return state;
  }
};
